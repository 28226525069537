import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Divider, Form, FormInstance } from 'antd';
import Flex from '../../../components/Flex';
import { FolderPermissionValue } from '../../../models/Types';
import FolderPermissionSelect from './FolderPermissionSelect';
import {
  FolderPermission,
  FolderPermissionAccessRight,
  FolderPermissionRole,
} from '../../../models/Settings';
import { folderNameRegex } from '../../documents/util';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Input } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  marginTop: {
    marginTop: theme.old.spacing.unit(1),
  },
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      marginLeft: theme.old.spacing.defaultPadding,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  label: {
    width: 100,
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
}));

const permissionsToValue: (
  permissions: FolderPermission[],
  accessRight: FolderPermissionAccessRight
) => FolderPermissionValue = (permissions, accessRight) => {
  const roles = permissions.find(
    (permission) => permission.accessRight === accessRight
  )?.roles;
  if (roles?.includes('write')) {
    return 'write';
  } else if (roles?.includes('read')) {
    return 'read';
  }
  return 'none';
};

export const valueToPermission: (
  value: FolderPermissionValue,
  accessRight: FolderPermissionAccessRight
) => FolderPermission | null = (value, accessRight) => {
  if (value === 'none') {
    return null;
  }
  const roles: FolderPermissionRole[] =
    value === 'write' ? ['read', 'write'] : ['read'];
  return {
    accessRight,
    roles,
  };
};

declare type FolderPermissionArray = [
  FolderPermissionValue,
  FolderPermissionValue,
  FolderPermissionValue,
  FolderPermissionValue,
  FolderPermissionValue,
];

export interface CreateFolderRequest {
  folderName: string;
  permissions: FolderPermissionArray | [];
}

interface NewFolderFormProps {
  parentPermissions?: FolderPermission[];
  usePermissions?: boolean;
  currentPath: string[];
  form: FormInstance<CreateFolderRequest>;
  onFinish: (value: CreateFolderRequest) => void;
  setInput?: (value: string) => void;
}

export const NewFolderForm: React.FC<NewFolderFormProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    usePermissions,
    parentPermissions,
    currentPath,
    form,
    onFinish,
    setInput = () => {},
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const initialValues: CreateFolderRequest = useMemo(
    () => ({
      folderName: '',
      permissions: parentPermissions
        ? [
            permissionsToValue(parentPermissions, 'admin'),
            permissionsToValue(parentPermissions, 'assistance'),
            permissionsToValue(parentPermissions, 'controller'),
            permissionsToValue(parentPermissions, 'member'),
            permissionsToValue(parentPermissions, 'guest'),
          ]
        : [],
    }),
    [parentPermissions]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFinish = (value: CreateFolderRequest) => {
    onFinish(value);
    form.resetFields();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    form.setFieldsValue(initialValues);
    inputRef.current?.focus();
  }, [initialValues, form]);
  //#endregion

  return (
    <Form<CreateFolderRequest>
      initialValues={initialValues}
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      onChange={() => setInput(form.getFieldValue('folderName'))}
    >
      <Flex.Row>
        <Flex.Column className={classes.fullWidth}>
          <Form.Item
            name="folderName"
            label={t(
              'settings:projectFileSystemStructureEditor.newFolder.content',
              {
                fileName:
                  currentPath.slice(-1)[0] ??
                  t(
                    'settings:projectFileSystemStructureEditor.newFolder.rootFolder'
                  ),
              }
            )}
            style={{ marginBottom: 0 }}
            rules={[
              () => ({
                async validator(rule, value) {
                  if (!!value.match(folderNameRegex)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    t(
                      'settings:projectFileSystemStructureEditor.newFolder.invalidFolderName'
                    )
                  );
                },
              }),
            ]}
          >
            <Input ref={inputRef} className={classes.marginTop} full />
          </Form.Item>
        </Flex.Column>
      </Flex.Row>
      {usePermissions && (
        <Flex.Row>
          <Flex.Column>
            <Form.Item name="permissions">
              <FolderPermissionSelectField />
            </Form.Item>
          </Flex.Column>
        </Flex.Row>
      )}
    </Form>
  );
};

export default NewFolderForm;

interface FolderPermissionSelectFieldProps {
  value?: FolderPermissionArray;
  onChange?: (value: FolderPermissionArray) => void;
}

const FolderPermissionSelectField: React.FC<
  FolderPermissionSelectFieldProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { value, onChange } = props;
  const theme = useTheme<PrioTheme>();
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const adminPermission = value[0];
  const assistancePermission = value[1];
  const controllerPermission = value[2];
  const memberPermission = value[3];
  const guestPermission = value[4];
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onUpdatePermission = (
    accessRight: FolderPermissionAccessRight,
    permissionValue: FolderPermissionValue
  ) => {
    switch (accessRight) {
      case 'admin': {
        onChange([
          permissionValue,
          assistancePermission,
          controllerPermission,
          memberPermission,
          guestPermission,
        ]);
        break;
      }
      case 'assistance': {
        onChange([
          adminPermission,
          permissionValue,
          controllerPermission,
          memberPermission,
          guestPermission,
        ]);
        break;
      }
      case 'controller': {
        onChange([
          adminPermission,
          assistancePermission,
          permissionValue,
          memberPermission,
          guestPermission,
        ]);
        break;
      }
      case 'member': {
        onChange([
          adminPermission,
          assistancePermission,
          controllerPermission,
          permissionValue,
          guestPermission,
        ]);
        break;
      }
      case 'guest': {
        onChange([
          adminPermission,
          assistancePermission,
          controllerPermission,
          memberPermission,
          permissionValue,
        ]);
        break;
      }
    }
  };
  //#endregion

  return (
    <Collapse ghost className={classes.collapse}>
      <Collapse.Panel
        header={
          <Flex.Row flex={1}>
            <div className={classes.label}>
              {t(
                'settings:projectFileSystemStructureEditor.panel.folderPermission'
              )}
            </div>
            <Divider className={classes.divider} />
          </Flex.Row>
        }
        key="1"
      >
        <Flex.Column childrenGap={theme.old.spacing.baseSpacing}>
          <FolderPermissionSelect
            field={'admin'}
            value={adminPermission}
            onChange={(value) => onUpdatePermission('admin', value)}
            showTitle
          />
          <FolderPermissionSelect
            field={'assistance'}
            value={assistancePermission}
            onChange={(value) => onUpdatePermission('assistance', value)}
            showTitle
          />
          <FolderPermissionSelect
            field={'controller'}
            value={controllerPermission}
            onChange={(value) => onUpdatePermission('controller', value)}
            showTitle
          />
          <FolderPermissionSelect
            field={'member'}
            value={memberPermission}
            onChange={(value) => onUpdatePermission('member', value)}
            showTitle
          />
          <FolderPermissionSelect
            field={'guest'}
            value={guestPermission}
            onChange={(value) => onUpdatePermission('guest', value)}
            showTitle
          />
        </Flex.Column>
      </Collapse.Panel>
    </Collapse>
  );
};
