import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Input, Checkbox, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import ContactListSkeleton from './ContactListSkeleton';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import useContactSearch from './useContactSearch';
import ContactList, { ContactListItem } from './ContactList';
import { Contact } from '../../../models/Contact';
import { Company } from '../../../models/Company';
import { ContactSearchType } from '../../../models/Types';
import {
  fetchInternalProjectContacts,
  fetchExternalProjectContacts,
} from '../../projects/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { debounceFunction } from '../../../util';
import { Button } from '@prio365/prio365-react-library';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  searchContainer: {
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
  },
  contactList: {
    flex: 1,
    borderTop: theme.old.borders.content,
  },
  contactListItem: {
    '& .ant-list-item-action': {
      visibility: 'hidden',
    },
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
      '& .ant-list-item-action': {
        visibility: 'visible',
      },
    },
    minHeight: 64,
  },
  selectAllCheckbox: {
    marginRight: theme.old.spacing.unit(2),
    '& .ant-checkbox': {
      top: 0,
    },
  },
  search: {
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
          background: 'transparent',
          padding: 0,
          width: 32,
          color: 'rgba(0, 0, 0, 0.6)',
          '&:hover': {
            background: 'transparent',
          },
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
  },
  searchSettingsIconWrapper: {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    height: '32px',
    width: '44px',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.old.palette.primaryColor,
    },
  },
  searchSettingsIconWrapperActive: {
    backgroundColor: theme.old.palette.primaryColor,
    color: theme.old.palette.chromaticPalette.white,
    '&:hover': {
      color: theme.old.palette.chromaticPalette.white,
      backgroundColor: theme.colors.base.primary.dark,
    },
  },
  searchSettingsDropdown: {
    backgroundColor: theme.colors.application.background.default,
    width: '140px',
    boxShadow:
      '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
  },
  dropdownOption: {
    width: '100%',
    padding: '4px',
    paddingLeft: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.colors.application.background.hover,
      cursor: 'pointer',
    },
  },
  dropdownOptionActive: {
    position: 'relative',
    backgroundColor: theme.colors.application.background.selected,
    '&:hover': {
      backgroundColor: theme.colors.application.background.hover,
    },
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 3,
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  dropdownOptionCloseIcon: {
    padding: '4px',
    '&:hover': {
      color: theme.old.palette.primaryColor,
      cursor: 'pointer',
    },
  },
}));

export interface ContactSelectionListRef {
  getAllItems: () => ContactListItem[];
}

interface ContactsSelectionListProps {
  className?: string;
  projectId?: string;
  type: ContactSearchType;
  pathPrefix?: string;
  activeId?: string;
  selectedListIds?: string[];
  setSelectedListIds?: (contacts: string[]) => void;
}

export const contactPropsArray = [
  'FirstName',
  'LastName',
  'Title',
  'Notes',
  'Email',
  'Phone',
  'Address',
  'City',
] as const;

export type ContactProps = (typeof contactPropsArray)[number];

export const ContactsSelectionList = forwardRef(
  (
    props: ContactsSelectionListProps,
    ref: React.ForwardedRef<ContactSelectionListRef>
  ) => {
    //#region ------------------------------ Defaults
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
      className,
      type,
      projectId,
      pathPrefix,
      activeId,
      selectedListIds,
      setSelectedListIds,
    } = props;
    const classes = useStyles();
    const theme = useTheme<PrioTheme>();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const [isOnline, setIsOnline] = useState<boolean>(false);

    const [searchInput, setSearchInput] = useState<string>('');

    const [selectedContactProp, setSelectedContactProp] =
      useState<ContactProps>(undefined);

    const [items, isFetching] = useContactSearch({
      searchTerm: searchInput,
      type,
      selectedContactProp,
      projectId,
      isOnline,
    });

    const allItemsSelected = useMemo(
      () =>
        selectedListIds.length > 0 && selectedListIds.length === items.length,
      [selectedListIds, items]
    );

    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const onSearchInputChange = debounceFunction(
      (e: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
        if (e.target.value === '') {
          setIsOnline(false);
        }
        if (selectedListIds.length > 0) {
          setSelectedListIds([]);
        }
      },
      1000
    );

    const selectAllClicked = () => {
      if (allItemsSelected) {
        setSelectedListIds([]);
      } else {
        setSelectedListIds(
          items.map(
            (item: ContactListItem) =>
              `${item.type}-${
                item.type === 'contact'
                  ? (item.value as Contact).contactId
                  : (item.value as Company).companyId
              }`
          )
        );
      }
    };

    const onDropdownOptionClicked = (e, prop: ContactProps) => {
      setSelectedContactProp(prop);
      setDropdownVisible(false);
    };

    const onResetDropdownOptionClicked = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedContactProp(null);
      setDropdownVisible(false);
    };
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      if (projectId) {
        if (type === 'internal' || type === 'members') {
          dispatch(fetchInternalProjectContacts(projectId));
        }
        if (type === 'external' || type === 'members') {
          dispatch(fetchExternalProjectContacts(projectId));
        }
      }
    }, [dispatch, projectId, type]);

    useEffect(() => {
      if (items.length === 0 && !isOnline && searchInput !== '') {
        setIsOnline(true);
      }
    }, [items, isOnline, searchInput]);
    //#endregion

    // #region ------------------------------ Ref
    React.useImperativeHandle(ref, () => ({
      getAllItems: () => {
        return items;
      },
    }));
    // #endregion

    //#region ------------------------------ Components
    const list = useMemo(
      () => (
        <ContactList
          items={items}
          selectable
          onSelect={(id: string) => {
            if (!selectedListIds.includes(id)) {
              setSelectedListIds([...selectedListIds, id]);
            }
          }}
          onUnselect={(id: string) => {
            setSelectedListIds(selectedListIds.filter((i) => i !== id));
          }}
          selection={selectedListIds}
          className={classes.contactList}
          listItemClassName={classes.contactListItem}
          pathPrefix={pathPrefix}
          activeId={activeId}
          showMoreClicked={() => {
            setIsOnline(true);
          }}
          showMore={!isOnline && searchInput !== ''}
        />
      ),
      [
        setSelectedListIds,
        items,
        selectedListIds,
        classes,
        pathPrefix,
        activeId,
        isOnline,
        searchInput,
      ]
    );
    //#endregion

    return (
      <Flex.Column
        className={classNames(classes.root, className)}
        id="prio-contact-selection-list"
      >
        <Flex.Row className={classes.searchContainer} alignItems="center">
          <Checkbox
            checked={allItemsSelected}
            onClick={selectAllClicked}
            className={classes.selectAllCheckbox}
          />
          <Input.Search
            allowClear
            size="middle"
            placeholder={t('contacts:contactSelectionList.search.placeHolder')}
            onChange={onSearchInputChange}
            className={classes.search}
            enterButton={
              isOnline && <FontAwesomeIcon icon={['fal', 'globe']} />
            }
            id="prio-contact-list-search-input"
          />
          <Dropdown
            visible={dropdownVisible}
            placement="bottomRight"
            trigger={['click']}
            overlayClassName={classes.searchSettingsDropdown}
            onVisibleChange={setDropdownVisible}
            overlay={
              <>
                {contactPropsArray.map((prop) => {
                  return (
                    <div
                      className={classNames(classes.dropdownOption, {
                        [classes.dropdownOptionActive]:
                          prop === selectedContactProp,
                      })}
                      onClick={(e) => {
                        onDropdownOptionClicked(e, prop);
                      }}
                    >
                      {t(
                        `contacts:contactSelectionList.searchSettings.${prop}`
                      )}
                      {prop === selectedContactProp && (
                        <FontAwesomeIcon
                          icon={['fal', 'times']}
                          className={classes.dropdownOptionCloseIcon}
                          onClick={(e) => onResetDropdownOptionClicked(e)}
                        />
                      )}
                    </div>
                  );
                })}
              </>
            }
          >
            <div
              className={classNames(classes.searchSettingsIconWrapper, {
                [classes.searchSettingsIconWrapperActive]:
                  !!selectedContactProp,
              })}
            >
              <div
                onContextMenu={(e) => {
                  e.preventDefault();
                  setSelectedContactProp(undefined);
                }}
              >
                <Button
                  type={selectedContactProp ? 'primary' : 'default'}
                  tooltip={
                    selectedContactProp
                      ? t(`contacts:contactSelectionList.tooltip`, {
                          prop: t(
                            `contacts:contactSelectionList.searchSettings.${selectedContactProp}`
                          ),
                        })
                      : undefined
                  }
                  tooltipPosition="topRight"
                  iconProp={['fal', 'gear']}
                  iconColor={
                    selectedContactProp
                      ? theme.colors.base.white.default
                      : undefined
                  }
                />
              </div>
            </div>
          </Dropdown>
        </Flex.Row>
        {items?.length === 0 && isFetching ? <ContactListSkeleton /> : list}
      </Flex.Column>
    );
  }
);

export default ContactsSelectionList;
