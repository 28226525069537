import { format, parseISO, startOfYear, isAfter, isBefore } from 'date-fns';
import { enUS, de } from 'date-fns/locale';
import { Contact, CreateContactRequest } from '../models/Contact';
import { Gender, DateString, ProgressStatus } from '../models/Types';
import moment from 'moment';
import { CurrencyCode, Money } from '../models/Accounting';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import equals from 'deep-equal';
import DOMPurify from 'dompurify';
import i18n from '../i18n';
import { Paths } from './GenericHelper';

const locales = { 'en-US': enUS, 'de-DE': de };

export const isTemporaryId: (id: string) => boolean = (id: string) => {
  return id ? id.startsWith('t-') : false;
};

export const html2text: (html: string) => string = (html) => {
  //remove code brakes and tabs
  let htmlContent = html.replace(/\n/g, '');
  htmlContent = htmlContent.replace(/\t/g, '');
  htmlContent = htmlContent.replace(/<!--(.|\s)*?-->/g, '');
  htmlContent = htmlContent.replace(/<head(.|\s)*?head>/g, '');
  //keep html brakes and tabs
  htmlContent = htmlContent.replace(/<\/td>/g, '\t');
  htmlContent = htmlContent.replace(/<\/table>/g, '\n');
  htmlContent = htmlContent.replace(/<\/tr>/g, '\n');
  htmlContent = htmlContent.replace(/<\/p>/g, '\n');
  htmlContent = htmlContent.replace(/<\/div>/g, '\n');
  htmlContent = htmlContent.replace(/<\/h(\D)?>/g, '\n');
  htmlContent = htmlContent.replace(/<br>/g, '\n');
  htmlContent = htmlContent.replace(/<br( )*\/>/g, '\n');

  //parse html into text

  var tag = document.createElement('div');
  tag.innerHTML = htmlContent;
  return tag.innerText;
};
export const debounceFunction = <F extends (...params: any[]) => void>(
  func: F,
  wait: number,
  immediate?: boolean
) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  } as F;
};

export const distinctArray = <T>(array: T[], byProperty: keyof T) => {
  return array.filter(
    (value, index, self) =>
      self.findIndex(
        (timeRecord) => timeRecord[byProperty] === value[byProperty]
      ) === index
  );
};

export const uploadFileStatusToProgressStatus: (
  value: UploadFileStatus
) => ProgressStatus = (value) => {
  switch (value) {
    case 'success':
    case 'done': {
      return 'success';
    }
    case 'error': {
      return 'exception';
    }
    case 'removed': {
      return 'normal';
    }
    case 'uploading': {
      return 'normal';
    }
  }
};

export const stringToColour = (str: string) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var j = 0; j < 3; j++) {
    var value = (hash >> (j * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const createTemporaryId: () => string = () =>
  `t-${Math.round(Math.random() * 1000000)}`;

export const longDateFormat = (date: Date, local: string) =>
  format(date, 'EE, dd.MM.yyyy HH:mm', { locale: locales[local] || de });
export const longDateFormatString = (date: string, local: string) =>
  longDateFormat(parseISO(date), local);

export const ShortDateTimeFormatString = (date: string, local?: string) =>
  format(parseISO(date), 'dd.MM.yy HH:mm', { locale: locales[local] || de });

export const DefaultDateTimeFormatString = (date: string, local?: string) =>
  format(parseISO(date), 'dd.MM.yyyy HH:mm', { locale: locales[local] || de });

export const MonthFormatString = (date: string, local?: string) =>
  format(parseISO(date), 'MMMM', { locale: locales[local] || de });

export const shortDateFormat = (date: Date, local: string) =>
  format(date, 'EE, dd.MM.', { locale: locales[local] || de });

export const shortWihtoutDayDateTimeFormatString = (
  date: string,
  local: string
) => format(parseISO(date), 'dd.MM. HH:mm', { locale: locales[local] || de });

export const longDateFormatStringForPrint = (date: string, local: string) =>
  `${format(parseISO(date), 'dd. MMMM yyyy', {
    locale: locales[local] || de,
  })} ${i18n.t('common:moment.at')} ${format(parseISO(date), 'HH:mm:ss', {
    locale: locales[local] || de,
  })}`;

export const shortDateFormatString = (date: string, local?: string) =>
  shortDateFormat(parseISO(date), local);

export const shortDateWithoutWeekdayFormat = (date: Date, local: string) =>
  format(date, 'dd. MMM', { locale: locales[local] || de });

export const shortDateWithoutWeekdayFormatString = (
  date: string,
  local?: string
) => shortDateWithoutWeekdayFormat(parseISO(date), local);

export const isCurrentYearOrFuture: (date: DateString) => boolean = (date) =>
  isAfter(parseISO(date), startOfYear(Date.now()));

export const isInPast: (date: DateString) => boolean = (date) =>
  isBefore(parseISO(date), Date.now());

export const fullDateFormat = (date: Date, local: string) => {
  try {
    return format(date, 'dd. MMM yyyy', { locale: locales[local] || de });
  } catch (error) {
    console.error('Invalid date object %d', date);
    console.error(error);
    return 'INVALID DATE';
  }
};

export const fullDateFormatFormatString = (date: string, local?: string) =>
  fullDateFormat(parseISO(date), local);

export const compactDateFormat = (date: Date, local: string) =>
  format(date, 'dd.MM.yyyy', { locale: locales[local] || de });

export const compactDateFormatString = (date: string, local?: string) =>
  compactDateFormat(parseISO(date), local);

export const compactDateWithWeekDayFormat = (date: Date, local: string) =>
  format(date, 'EE dd.MM.yyyy', { locale: locales[local] || de });

export const compactDateWithWeekDayFormatString = (
  date: string,
  local?: string
) => compactDateWithWeekDayFormat(parseISO(date), local);

export const initials = (firstName: string, lastName: string) =>
  (
    (firstName?.substring(0, 1) ?? '') + (lastName?.substring(0, 1) ?? '')
  ).toUpperCase() || '?';

export const fullContactTitle = (contact: Contact | CreateContactRequest) =>
  `${contact.firstName} ${contact.lastName}`;

export const salutationFromGender = (gender: Gender) => {
  switch (gender) {
    case 'male':
      return 'Herr';
    case 'female':
      return 'Herr';
    case 'diverse':
      return '';
  }
};

export function distinct<T>(array: T[]): T[] {
  return Array.from<T>(new Set<T>(array));
}

export function distinctByProperty<T>(array: T[], filterProp: keyof T): T[] {
  return Array.from(new Set(array.map((item) => item[filterProp]))).map(
    (value) => array.find((item) => equals(value, item[filterProp as string]))
  );
}

export const groupBy: <T>(
  array: T[],
  property: string
) => { [key: string]: T[] } = <T>(array: T[], property: string) => {
  return array?.reduce(function (groups, item) {
    const val = item[property];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
};

export const groupByFunction: <T>(
  array: T[],
  func: (item: T) => string
) => { [key: string]: T[] } = <T>(array: T[], func) => {
  return array.reduce(function (groups, item) {
    const val = func(item);
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
};

export const formatMinutes = (minutes: number, showFormat: boolean = true) => {
  const hours = minutes / 60;
  const totalHours = Math.floor(hours);
  const residualMinutes = Math.round(minutes - totalHours * 60);

  return `${totalHours}:${
    residualMinutes < 10 ? `0${residualMinutes}` : residualMinutes
  }${showFormat ? ' h' : ''}`;
};

export const formatMinutesFloat = (
  minutes: number,
  showFormat: boolean = true
) => {
  const hours = minutes / 60;
  const totalHours = Math.floor(hours);
  const residualMinutes = Math.round(((minutes - totalHours * 60) / 60) * 100);

  return `${totalHours},${
    residualMinutes < 10 ? `0${residualMinutes}` : residualMinutes
  }${showFormat ? ' h' : ''}`;
};

export const truncateString = (str: string, maxChars: number) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= maxChars) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, maxChars) + '...';
};

export const isoDateFormat = 'YYYY-MM-DD';
export const todayDateString: DateString = moment().format(isoDateFormat);

export async function asyncForEach<T>(
  array: T[],
  callback: (t: T, index: number, array: T[]) => void
) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const formatHumanFileSize: (
  bytes: number,
  si?: boolean,
  decimalPlaces?: number,
  showUnits?: boolean
) => string = (
  bytes: number,
  si: boolean = true,
  decimalPlaces: number = 1,
  showUnits = true
) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const germanNumberFormatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPlaces,
  });

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** decimalPlaces;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  if (!showUnits) {
    return germanNumberFormatter.format(bytes);
  }
  return germanNumberFormatter.format(bytes) + ' ' + units[u];
};

export const formatNumber = (number: number, maxDecimalPlaces: number = 4) => {
  const germanNumberFormatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDecimalPlaces,
  });
  return germanNumberFormatter.format(number);
};
export const formatMoney = (
  money?: Money,
  { useCurrencySymbol, maxDecimalPlaces } = {
    useCurrencySymbol: true,
    maxDecimalPlaces: 2,
  }
) => {
  var amount =
    formatMoneyNumber(0, { maxDecimalPlaces }) +
    ' ' +
    (useCurrencySymbol ? currencySymbol('EUR') : 'EUR');

  if (money?.value) {
    amount =
      formatMoneyNumber(money.value, { maxDecimalPlaces }) +
      ' ' +
      (useCurrencySymbol ? currencySymbol(money.isoCode) : money.isoCode);
  }
  return amount;
};

export const formatMoneyNumber = (
  value: number,
  { maxDecimalPlaces } = {
    maxDecimalPlaces: 2,
  }
) => {
  const germanNumberFormatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: maxDecimalPlaces,
  });
  return germanNumberFormatter.format(value);
};

export const currencySymbol = (isoCode: CurrencyCode) => {
  switch (isoCode) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    case null:
      return '?€?';
  }
};

export const ibanPattern = /^DE(?:\s*[0-9a-zA-Z]\s*){20}$/;
export const bicPattern =
  /^([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)$/;
export const defaultPhonePattern = /^(\+\d{1,2})\s\d{3}\s(\d+-)*(\d)+$/;
export const defaultPhonePlaceholder = '+49 123 456789-12';

export const defaultProjectNrPlaceholder = '000-000';

export const sanitizeHTML: (html: string) => string = (html: string) => {
  const sanitizedBody = DOMPurify.sanitize(html, {
    ALLOWED_URI_REGEXP:
      // eslint-disable-next-line
      /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|blob|cid|xmpp|xxx):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i,
  });
  return sanitizedBody;
};

export const urltoFile: (
  url: string,
  filename: string,
  mimeType: string
) => Promise<File> = (url: string, filename: string, mimeType: string) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export const blobToByteArray: (blob: Blob) => Promise<Uint8Array> = (
  blob: Blob
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      const uint8Array = new Uint8Array(arrayBuffer);
      resolve(uint8Array);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
};

export const getValueByAccessor = <T, K extends Paths<T>>(
  obj: T,
  accessor: K
) => {
  const path = accessor.split('.');
  return path.reduce((acc, key) => (!acc ? null : acc[key]), obj);
};

/**
 * Generates a unique list of filter options based on a specific property path in an array of items.
 * Each filter option includes a `label` and `value`, suitable for dropdowns or selection inputs.
 *
 * @param items - An array of items to generate filter options from.
 * @param accessorPath - The dot-separated path to the property to filter by (e.g., 'data.projectId').
 * @param labelFn - An optional function to transform each unique value into a label string.
 *                  If not provided, each value is converted to a string directly.
 * @returns An array of filter options, each with a `label` (displayed text) and `value` (underlying data).
 *
 * @example
 * const records = [{ data: { projectId: 1 } }, { data: { projectId: 2 } }, { data: { projectId: 1 } }];
 * generateFilterOptions(records, 'data.projectId');
 * // Returns [{ label: '1', value: '1' }, { label: '2', value: '2' }]
 */
export const generateFilterOptions = <T>(
  items: T[],
  accessorPath: Paths<T>,
  labelFn?: (value: string | number) => string
): { label: string; value: string | number }[] => {
  const uniqueValues = new Set(
    items
      .map((record) => getValueByAccessor(record, accessorPath))
      .filter(Boolean)
  );

  return Array.from(uniqueValues)
    .map((value) => ({
      label: labelFn ? labelFn(value) : String(value),
      value: String(value),
    }))
    .filter((option) => option.label && option.value);
};
