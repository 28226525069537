import FroalaEditor from 'froala-editor';

export const DEFAULT_BR_VALUE = `<div><span><br></span></div>`;

export const DEFAULT_TOOLBAR_BUTTONS = {
  text: {
    buttons: [
      'fontFamily',
      'fontSize',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'textColor',
      'backgroundColor',
      'clearCustomFormatting',
    ],
    align: 'left',
  },
  paragraph: {
    buttons: [
      'alignLeft',
      'alignCenter',
      'formatOL',
      'formatUL',
      'outdent',
      'indent',
      'formatOLSimple',
      'alignRight',
      'alignJustify',
      'paragraphFormat',
      'paragraphStyle',
      'lineHeight',
    ],
    align: 'left',
  },
  additional: {
    buttons: ['insertTable', 'insertImage', 'textBlocks', 'insertLink'],
    align: 'left',
  },
};

export const DEFAULT_FROALA_CONFIG = {
  key: 'sZH1rB3A5E7B5F4G4iAa1Tb1YZNYAh1CUKUEQOHFVANUqD1G1F4J4B1C8E7D2C4B4==',
  charCounterCount: false,
  tabSpaces: 6,
  spellcheck: true,
  theme: 'royal',
  enter: FroalaEditor.ENTER_DIV,
  // htmlRemoveTags: ['script'],
  fontSizeUnit: 'px',
  fontSizeSelection: true,
  fontSizeDefaultSelection: '14',
  fontSize: [
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '18',
    '24',
    '30',
    '36',
    '48',
    '60',
    '72',
    '96',
  ],
  fontFamilySelection: true,
  fontFamilyDefaultSelection: 'Segoe UI',
  fontFamily: {
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"':
      'Segoe UI',
    'Arial,Helvetica,sans-serif': 'Arial',
    "'Arial Black',sans-serif": 'Arial Black',
    'Calibri,sans-serif': 'Calibri',
    "'Courier New',monospace": 'Courier New',
    'Garamond,serif': 'Garamond',
    'Georgia,serif': 'Georgia',
    "'Gill Sans',sans-serif": 'Gill Sans',
    'Impact,Charcoal,sans-serif': 'Impact',
    'Lucida,monospace': 'Lucida',
    'Monaco,monospace': 'Monaco',
    'Palatino,serif': 'Palatino',
    'Tahoma,Geneva,sans-serif': 'Tahoma',
    "'Times New Roman',Times,serif": 'Times New Roman',
    "'Trebuchet MS', sans-serif": 'Trebuchet MS',
    'Verdana,Geneva,sans-serif': 'Verdana',
    Webdings: 'Webdings',
  },
  dragInline: true,
  imageDefaultWidth: 700,
  imagePaste: true,
  imageDefaultAlign: 'left',
  lineHeights: {
    Default: '1',
    Single: '1',
    '1.15': '1.15',
    '1.5': '1.5',
    Double: '2',
  },
};

export const DEFAULT_FROALA_EVENTS = {
  'paste.afterCleanup': function (clipboard_html: string) {
    const simpleCopyMatch = clipboard_html.match(
      /^(<div>)+([^<>]*|(<span\s?[\d\w\-;:=,\s"]*>)+(<strong>|<em>|<s>|<u>)*[^<>]*(<\/strong>|<\/em>|<\/s>|<\/u>)*(<\/span>)+)(<\/div>)+$/
    );
    if (simpleCopyMatch?.[2]) {
      return simpleCopyMatch[2];
    }
    const lineBreakCopyMatch = clipboard_html.match(
      /^((<div>)+(<br>|&nbsp;|(<span\s?[\d\w\-;:=,\s"]*>)+(<strong>|<em>|<s>|<u>)*(<br>|&nbsp;)(<\/strong>|<\/em>|<\/s>|<\/u>)*(<\/span>)+)(<\/div>)+)(.*)$/
    );
    if (lineBreakCopyMatch?.[lineBreakCopyMatch?.length - 1]) {
      try {
        const { atStart, atEnd } = this.selection.info(
          this.selection.element()
        );
        if (atStart && atEnd) {
          const selectionStyle = (this.selection.element() as HTMLElement)
            .style;
          const fontFamily = selectionStyle.fontFamily;
          const fontSize = selectionStyle.fontSize;
          return (
            `<div><span style="${
              fontFamily ? `font-family: ${fontFamily};` : ''
            };${fontFamily && fontSize ? ' ' : ''}${
              fontSize ? `font-size: ${fontSize};` : ''
            }"><br></span></div>` +
            lineBreakCopyMatch[lineBreakCopyMatch?.length - 1]
          );
        }
        if (atEnd) {
          return lineBreakCopyMatch[lineBreakCopyMatch?.length - 1];
        }
        return clipboard_html;
      } catch (e) {
        console.error('Error in Editor during paste - ', e);
      }
    }
    return clipboard_html;
  },
  keydown: function (keydownEvent: KeyboardEvent) {
    switch (keydownEvent.key) {
      case 'Enter': {
        try {
          const { atStart, atEnd } = this.selection.info(
            this.selection.element()
          );
          if (atEnd || atStart) {
            const selectionStyle = (this.selection.element() as HTMLElement)
              .style;
            const fontFamily = selectionStyle.fontFamily;
            const fontSize = selectionStyle.fontSize;
            if (fontFamily) {
              this.fontFamily.apply(fontFamily);
            }
            if (fontSize) {
              this.fontSize.apply(fontSize);
            }
            if (atStart) {
              if (!this.selection.isFull()) {
                try {
                  const previousLine = (this.selection.element() as HTMLElement)
                    ?.parentNode?.previousSibling;
                  if (
                    previousLine &&
                    previousLine.childNodes.length === 1 &&
                    previousLine.childNodes[0].nodeName === 'BR'
                  ) {
                    let newChild = document.createElement('span');
                    newChild.style.cssText = `${
                      fontFamily ? `font-family: ${fontFamily};` : ''
                    };${fontFamily && fontSize ? ' ' : ''}${
                      fontSize ? `font-size: ${fontSize};` : ''
                    }`;
                    newChild.appendChild(document.createElement('br'));
                    previousLine.childNodes[0].replaceWith(newChild);
                  }
                } catch (err) {
                  console.error(
                    'Error in creating new line while replacing nodes',
                    err
                  );
                }
              }
            }
          }
        } catch (e) {
          console.error('Error in Editor during keydown - ', e);
        } finally {
          return;
        }
      }
      default: {
        return;
      }
    }
  },
};
