/**
 * @file Index.tsx the react container for the sidebar application
 * @author Jonathan Sommer <j.sommer@fc-gruppe.de>
 * @copyright (c) FC-Digitalisierung GmbH 2020
 * @license All rights reserved.
 */

//import "office-ui-fabric-react/dist/css/fabric.min.css";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './i18n';
import App from './apps/main/App';
import 'moment/locale/de';
import { configuration, logout } from './modules/auth/authUtils';
import { AppContext } from './models/Types';
import * as OfficeJs from './util/officeJs';
import * as ConfigValues from './util/configValues';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import packageJson from '../package.json';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import React from 'react';
let isOfficeInitialized = false;
let appContext: AppContext = 'browser';
const title = 'PRIO';

const pca = new PublicClientApplication(configuration);

// Component
const AppProvider = () => (
  <MsalProvider instance={pca}>
    <App title={title} isOfficeInitialized={isOfficeInitialized} />
  </MsalProvider>
);

//add seq logging
if (!ConfigValues.REACT_APP_API_REDIRECT_URL.includes('prio.local')) {
  Sentry.setContext('customer', {
    domain: ConfigValues.REACT_APP_API_REDIRECT_URL,
  });

  Sentry.init({
    dsn: 'https://9f77435a9559fadc91ffcfc8737afe67@sentry.prio365.app/5',
    integrations: [
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
      new BrowserTracing(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    release: packageJson.version,
    autoSessionTracking: false,
    environment: ConfigValues.REACT_APP_API_REDIRECT_URL.includes('prio.local')
      ? 'local'
      : ConfigValues.REACT_APP_API_REDIRECT_URL.includes(
          'fc-digitalisierung.de'
        )
      ? 'dev'
      : ConfigValues.REACT_APP_API_REDIRECT_URL.includes('fc-coding.de')
      ? 'qa'
      : ConfigValues.REACT_APP_API_REDIRECT_URL.includes('prio365.app')
      ? 'staging'
      : 'production',
    tracesSampleRate: 0.5,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      "Error: Connection disconnected with error 'Error: WebSocket closed with status code: 1006 ().'",
      "Error: Connection disconnected with error 'Error: Server returned an error on close: Connection closed with an error.",
      'uuidv4() is deprecated. Use v4() from the uuid module instead.',
      'Error: Failed to start the connection: Error',
      'Error: Failed to complete negotiation with the server: Error',
      "Error: Connection disconnected with error 'Error: Server timeout elapsed without receiving a message from the server.'",
      'Error: Failed to start the connection: TypeError: Failed to fetch',
      'Error: Failed to complete negotiation with the server: TypeError: Failed to fetch',
      'Error: Failed to complete negotiation with the server: Error: Unauthorized',
      'Error: Failed to start the connection: Error: Unauthorized',
      'Error: Server returned handshake error: Handshake was canceled.',
      "Error: Connection disconnected with error 'Error: Server returned handshake error: Handshake was canceled.'",
      "Error: Failed to start the transport 'WebSockets': Error: There was an error with the transport.",
      "Error: Failed to start the transport 'ServerSentEvents': Error: Error occurred",
      'BrowserAuthError: user_cancelled: User cancelled the flow.',
    ],
  });
}

const render = () =>
  ReactDOM.render(<AppProvider />, document.getElementById('root'));

/* Render application after Office initializes */
(async () => {
  if (ConfigValues.REACT_APP_ENVIRONMENT === 'office') {
    const tryOffice = () => {
      return new Promise<{
        host: Office.HostType;
        platform: Office.PlatformType;
      }>(async (resolve) => {
        try {
          const officeJsResult = await Office.onReady();
          resolve(officeJsResult);
        } catch {
          resolve(null);
        }
      });
    };
    const retry = (
      officeResult: { host: Office.HostType; platform: Office.PlatformType },
      times: number,
      milliseconds: number
    ) => {
      return new Promise<{
        host: Office.HostType;
        platform: Office.PlatformType;
      }>(async (resolve) => {
        if (officeResult) {
          resolve(officeResult);
        } else if (times > 0) {
          const timer = setTimeout(async () => {
            clearTimeout(timer);
            await tryOffice().then((newResult) => {
              resolve(retry(newResult, times - 1, milliseconds));
            });
          }, milliseconds);
        } else {
          resolve(null);
        }
      });
    };
    await tryOffice()
      .then((officeResult) => {
        return retry(officeResult, 4, 500);
      })
      .then((res) => {
        if (res.host === Office.HostType.Outlook) {
          appContext = 'outlook';
          OfficeJs.setOutlookContext(true);
          console.info('initialize done - Outlook AddIn');
        } else {
          console.warn(
            'PRIO started from office context, but not Outlook. This should not happen...'
          );
        }
      });
  } else if (ConfigValues.REACT_APP_ENVIRONMENT === 'teams') {
    appContext = 'teams';
    OfficeJs.setTeamsContext(true);
    console.info('initialize done - Teams Application');
  } else {
    appContext = 'browser';
    console.info('initialize done - Browser Application');
  }

  OfficeJs.setAppContext(appContext);

  isOfficeInitialized = true;

  if (window.location.pathname.startsWith('/logout/')) {
    logout();
  } else {
    render();
  }
})();

serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: any) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  },
});
