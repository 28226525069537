import React, { useMemo, useState } from 'react';
import { Select } from '@prio365/prio365-react-library';
import { debounceFunction } from '../../../util';
import { useQuery } from '@tanstack/react-query';
import {
  apiFetchExternalJobTitleSuggestions,
  apiFetchInternalJobTitleSuggestions,
} from '../../settings/api';
import { Size } from '@prio365/prio365-react-library/lib/Select/components/Select';

const debouncedSetSearchValue = debounceFunction((value, setSearchValue) => {
  if (value) {
    setSearchValue(value);
  } else {
    setSearchValue(null);
  }
}, 500);

interface JobTitleSelectProps {
  /**
   * Whether to limit the job titles suggestions to internal or external only. If not provided, both internal and external job titles will be shown.
   **/
  limitation?: 'internal' | 'external';
  /**
   * Method to run when the value of the select component changes.
   * @param value
   * @returns
   */
  onChange?: (value: string) => void;
  /**
   * The value of the select component.
   */
  value?: string;
  /**
   * Whether the select component is disabled.
   */
  disabled?: boolean;
  /**
   * The size of the select component.
   * @default 'small'
   */
  size?: Size;
}

const JobTitleSelect: React.FC<JobTitleSelectProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    limitation = undefined,
    onChange,
    value,
    disabled = false,
    size = 'small',
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [searchValue, setSearchValue] = useState<string>(null);

  const { data: __internalJobTitles } = useQuery({
    queryKey: ['internalJobTitleSuggestions'],
    queryFn: apiFetchInternalJobTitleSuggestions,
    staleTime: 1000 * 60 * 60 * 20, // 20 hours
  });

  const { data: __externalJobTitles } = useQuery({
    queryKey: ['externalJobTitleSuggestions'],
    queryFn: apiFetchExternalJobTitleSuggestions,
    staleTime: 1000 * 60 * 60 * 20, // 20 hours
  });

  const jobTitleOptions = useMemo(() => {
    const _internalJobTitles = __internalJobTitles?.data ?? [];
    const _externalJobTitles = __externalJobTitles?.data ?? [];
    const internalJobTitles = _internalJobTitles.map((title) => ({
      label: title.name,
      value: title.name,
      searchValue: title.name,
    }));
    const externalJobTitles = _externalJobTitles.map((title) => ({
      label: title.name,
      value: title.name,
      searchValue: title.name,
    }));
    if (limitation === 'internal') {
      return internalJobTitles;
    }
    if (limitation === 'external') {
      return externalJobTitles;
    }
    return [...internalJobTitles, ...externalJobTitles];
  }, [__internalJobTitles, __externalJobTitles, limitation]);

  const jobTitleOptionsWithSearchValue = useMemo(() => {
    return [
      ...jobTitleOptions,
      ...(searchValue
        ? [
            {
              label: searchValue,
              value: searchValue,
            },
          ]
        : []),
    ];
  }, [jobTitleOptions, searchValue]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleSearch = (value: string) => {
    debouncedSetSearchValue(value, setSearchValue);
  };
  //#endregion

  return (
    <Select
      showSearch
      options={jobTitleOptionsWithSearchValue}
      onChange={onChange}
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
      onSearch={handleSearch}
      value={value ?? ''}
      disabled={disabled}
      size={size}
    />
  );
};

export default JobTitleSelect;
