import React, { useState, useCallback } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import {
  InternalJobTitleSuggestion,
  CreateInternalJobTitleSuggestion,
} from '../../../models/InternalJobTitleSuggestion';
import Flex from '../../../components/Flex';
import { Button } from '@prio365/prio365-react-library';
import equals from 'deep-equal';
import { InternalJobTitlesTable } from './InternalJobTitlesTable';
import { InternalJobTitleSuggestionId } from '../../../models/Types';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  apiCreateInternalJobTitleSuggestion,
  apiDeleteInternalJobTitleSuggestion,
  apiFetchInternalJobTitleSuggestions,
  apiUpdateInternalJobTitleSuggestion,
} from '../api';
import { notification } from 'antd';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
  },
  spin: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
  },
  column: {},
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
}));

interface InternalJobTitlesPageProps {
  className?: string;
}

export const InternalJobTitlesPage: React.FC<InternalJobTitlesPageProps> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const { className } = props;
  const queryClient = useQueryClient();

  const { data: __internalJobTitles, isFetching } = useQuery({
    queryKey: ['internalJobTitleSuggestions'],
    queryFn: apiFetchInternalJobTitleSuggestions,
    staleTime: 1000 * 60 * 60 * 20, // 20 hours
  });

  const internalJobTitles = __internalJobTitles?.data ?? [];

  const [updatedInternalJobTitles, setUpdatedInternalJobTitles] =
    useState<InternalJobTitleSuggestion[]>(null);

  const onIsDirtyChangedInternalJobTitles = useCallback(
    (isDirty: boolean, hourlyRates: InternalJobTitleSuggestion[]) => {
      if (isDirty) {
        setUpdatedInternalJobTitles(hourlyRates);
      } else {
        setUpdatedInternalJobTitles(null);
      }
    },
    [setUpdatedInternalJobTitles]
  );

  const onNewJobTitle = async (
    newJobTitle: CreateInternalJobTitleSuggestion
  ) => {
    const { result } = await apiCreateInternalJobTitleSuggestion(newJobTitle);

    if (result.status >= 200 && result.status < 300) {
      queryClient.invalidateQueries({
        queryKey: ['internalJobTitleSuggestions'],
        refetchType: 'all',
      });
    } else {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.createTitleSuggestionError'),
      });
    }
  };

  const onDelete = async (
    internalJobTitleSuggestionId: InternalJobTitleSuggestionId
  ) => {
    const { result } = await apiDeleteInternalJobTitleSuggestion(
      internalJobTitleSuggestionId
    );

    if (result.status >= 200 && result.status < 300) {
      queryClient.invalidateQueries({
        queryKey: ['internalJobTitleSuggestions'],
        refetchType: 'all',
      });
    } else {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.deleteTitleSuggestionError'),
      });
    }
  };

  const updateJobTitle = async (
    updatedInternalJobTitle: InternalJobTitleSuggestion,
    originalInternalJobTitle: InternalJobTitleSuggestion
  ) => {
    const { result } = await apiUpdateInternalJobTitleSuggestion(
      updatedInternalJobTitle,
      originalInternalJobTitle
    );
    if (result.status >= 200 && result.status < 300) {
      queryClient.invalidateQueries({
        queryKey: ['internalJobTitleSuggestions'],
        refetchType: 'all',
      });
    } else {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.updateTitleSuggestionError'),
      });
    }
  };

  const onSave = async () => {
    if (updatedInternalJobTitles !== null) {
      updatedInternalJobTitles.forEach((updatedInternalJobTitle) => {
        const originalInternalJobTitle = internalJobTitles.find(
          (ts) =>
            ts.internalJobTitleSuggestionId ===
            updatedInternalJobTitle.internalJobTitleSuggestionId
        );
        if (
          originalInternalJobTitle &&
          !equals(updatedInternalJobTitle, originalInternalJobTitle)
        ) {
          updateJobTitle(updatedInternalJobTitle, originalInternalJobTitle);
        }
      });
    }
  };

  if (
    isFetching &&
    (internalJobTitles === null || internalJobTitles.length === 0)
  ) {
    return (
      <div className={classes.spin}>
        <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
          <PrioSpinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column
        className={classes.column}
        childrenGap={theme.old.spacing.unit(2)}
      >
        <InternalJobTitlesTable
          className={classes.shadow}
          internalJobTitles={internalJobTitles}
          onIsDirtyChanged={onIsDirtyChangedInternalJobTitles}
          onNewJobTitle={onNewJobTitle}
          onDelete={onDelete}
        />

        <Flex.Row justifyContent="flex-end">
          <Button
            disabled={
              !updatedInternalJobTitles ||
              updatedInternalJobTitles.filter(
                (job) => !job?.name || job?.name === ''
              )?.[0]
                ? true
                : false
            }
            onClick={onSave}
          >
            {t('common:actions.save')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </div>
  );
};

export default InternalJobTitlesPage;
