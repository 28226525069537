import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Form, notification } from 'antd';
import { Button, Select, Modal } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { initials, fullContactTitle } from '../../../../util';
import { getInitialsColorHexFromName } from '../../../../util/initialColors';
import { Employee } from '../../../../models/Employee';
import { InternalContact } from '../../../../models/Contact';
import { useDispatch, useSelector } from 'react-redux';
import {
  archiveContactAsOfficeRole,
  unarchiveContactAsOfficeRole,
  updateInternalContact,
} from '../../../contacts/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  apiCreateMessageDraft,
  apiUpdateMessageDraft,
} from '../../../mail/api';
import { makePrioStyles } from '../../../../theme/utils';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { useForm } from 'antd/lib/form/Form';
import {
  getCompaniesByIdState,
  getOfficesByIdState,
  getTitleSuggestions,
} from '../../../../apps/main/rootReducer';
import { getUserMe, RootReducerState } from '../../../../apps/main/rootReducer';
import { User } from '../../../../models/User';
import Input from '@prio365/prio365-react-library/lib/Input';
import { TitleSuggestion } from '../../../../models/TitleSuggestion';
import { fetchTitleSuggestions } from '../../../settings/actions/titleSuggestions';

const useStyles = makePrioStyles((theme) => ({
  root: {
    color: theme.colors.application.typography.default,
  },
  title: {
    '& > svg': {
      visibility: 'hidden',
    },
    '&:hover > svg': {
      visibility: 'visible',
    },
    '& > h2': {
      marginBottom: 0,
    },
  },
  companyInformation: {
    fontSize: theme.font.fontSize.small,
  },
  header: {
    fontSize: '20px',
    fontWeight: theme.font.fontWeight.bold,
  },
  formItem: {
    '& .ant-form-item-control-input-content': {
      '& > div': {
        width: '100%!important',
      },
    },
  },
  titleHeader: {
    fontSize: theme.font.fontSize.extraSmall,
    paddingLeft: theme.spacing.regular,
  },
}));

interface EditableContact {
  firstName: string;
  lastName: string;
  title: string;
}

interface PersonnelFileHeaderProps {
  className?: string;
  contact: InternalContact;
  offBoard: () => void;
  employee: Employee;
  noAvatar?: boolean;
  pathPrefix?: string;
}

export const PersonnelFileHeader: React.FC<PersonnelFileHeaderProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, noAvatar, contact, employee } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();

  const location = useLocation();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const fullContactName = fullContactTitle(contact);

  const userMe = useSelector<RootReducerState, User>(getUserMe);

  const computedInitials = initials(contact.firstName, contact.lastName);

  const avatarColor = getInitialsColorHexFromName(fullContactName);

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [archiveModalVisible, setArchiveModalVisible] =
    useState<boolean>(false);

  const officesByIdState = useSelector(getOfficesByIdState);
  const companiesByIdState = useSelector(getCompaniesByIdState);

  const contactOffice = officesByIdState[contact?.officeId];
  const contactCompany = companiesByIdState[contact?.companyId];

  const [isRenameModalVisible, setIsRenameModalVisible] =
    useState<boolean>(false);
  const [editableContact, setEditableContact] = useState<EditableContact>({
    firstName: contact.firstName,
    lastName: contact.lastName,
    title: contact.title,
  });
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const handleOpenEMailMeFromHR = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    doShowNewEMailMeMessageFromContact();
  };

  const archive = () => {
    setArchiveModalVisible(true);
  };
  const handleArchive = () => {
    dispatch(archiveContactAsOfficeRole(contact.contactId, contact.officeId));
    setArchiveModalVisible(false);
    navigate('/module/prio/hr/personnelFiles/');
  };

  const unarchive = () => {
    dispatch(unarchiveContactAsOfficeRole(contact.contactId, contact.officeId));
  };

  const doShowNewEMailMeMessageFromContact = async () => {
    setIsCreating(true);
    const { data: message } = await apiCreateMessageDraft('me');
    if (message) {
      message.toRecipients = [
        {
          emailAddress: {
            name: '',
            address: contact.eMail ?? contact.eMail2,
          },
        },
      ];
      await apiUpdateMessageDraft('me', message.id, message);
      const width = window.screen.availWidth / 2;
      const height = window.screen.availHeight * 0.6;
      window.open(
        `/view/me/composer/${message.id}`,
        '_blank',
        `width=${width},height=${height},top=${height / 4}`
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.createDraftError'),
      });
    }
    setIsCreating(false);
  };

  const handleNameEdit = () => {
    setIsRenameModalVisible(true);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(fetchTitleSuggestions());
  }, [dispatch]);
  //#endregion

  return (
    <Flex.Row className={classNames(classes.root, className)}>
      {!noAvatar && (
        <Avatar
          size={64}
          style={{
            backgroundColor: avatarColor,
            fontSize: 18,
            fontWeight: theme.old.typography.fontWeight.bold,
            minWidth: 64,
          }}
        >
          {computedInitials}
        </Avatar>
      )}
      <Flex.Column
        padding={
          noAvatar
            ? `0 ${theme.old.spacing.unit(2)}px 0 0`
            : `0 ${theme.old.spacing.unit(2)}px`
        }
        flex={2}
        justify-content="center"
      >
        <Flex.Row
          alignItems="baseline"
          className={classes.title}
          childrenGap={theme.old.spacing.unit(1)}
        >
          <Flex.Row
            alignItems="baseline"
            className={classes.title}
            childrenGap={theme.old.spacing.unit(1)}
          >
            <div className={classes.header}>
              {fullContactName}
              <span className={classes.titleHeader}>{contact.title}</span>
            </div>
            <FontAwesomeIcon
              icon={['fal', 'pen']}
              color={theme.old.typography.colors.muted}
              style={{ cursor: 'pointer' }}
              onClick={handleNameEdit}
            />
          </Flex.Row>
        </Flex.Row>
        <div className={classes.companyInformation}>
          {contactCompany?.fullName}
          {contactCompany?.fullName !== contactCompany?.shortName
            ? contactCompany?.shortName
            : ''}
          {contactOffice && !contactOffice.isMainOffice && contactOffice.name}
        </div>
      </Flex.Column>
      {userMe.prioData.globalRoles.includes('globalAdmin') &&
      location.pathname.match(/\/settings\/users/) ? (
        <Flex.Row>
          <Button
            type="link"
            iconProp={['fal', 'archive']}
            onClick={contact.isArchived ? unarchive : archive}
          >
            {contact.isArchived
              ? t('hr:personnelFile.actions.unarchive')
              : t('hr:personnelFile.actions.archive')}
          </Button>
        </Flex.Row>
      ) : (
        <Flex.Row alignItems="center" childrenGap={8}>
          {(contact.eMail || employee.eMail) && (
            <Button
              type="link"
              iconProp={['fal', 'envelope']}
              onClick={handleOpenEMailMeFromHR}
              loading={isCreating}
              disabled={isCreating}
            >
              {t('hr:personnelFile.actions.eMail')}
            </Button>
          )}
          {(contact.cellphone || contact.phone || employee?.phone) && (
            <Button
              type="link"
              iconProp={['fal', 'phone']}
              href={`tel:${
                contact.cellphone ?? contact.phone ?? employee?.phone
              }`}
            >
              {t('hr:personnelFile.actions.call')}
            </Button>
          )}
          <Button
            type="link"
            iconProp={['fal', 'archive']}
            onClick={contact.isArchived ? unarchive : archive}
          >
            {contact.isArchived
              ? t('hr:personnelFile.actions.unarchive')
              : t('hr:personnelFile.actions.archive')}
          </Button>
        </Flex.Row>
      )}
      <Modal
        title={t('hr:personnelFile.archiveModal.title')}
        visible={archiveModalVisible}
        onOk={handleArchive}
        okText={t('hr:personnelFile.archiveModal.okButtonText')}
        cancelText={t('hr:personnelFile.archiveModal.cancelButtonText')}
        onClose={() => setArchiveModalVisible(false)}
      >
        {t('hr:personnelFile.archiveModal.message')}{' '}
      </Modal>
      <Modal
        title={t('hr:personnelFile.modal.title')}
        visible={isRenameModalVisible}
        okText={t('hr:personnelFile.modal.okText')}
        cancelText={t('hr:personnelFile.modal.cancelText')}
        okButtonProps={{
          disabled: !!!editableContact.lastName && !!!editableContact.firstName,
        }}
        onOk={() => {
          dispatch(
            updateInternalContact(
              editableContact,
              contact.contactId,
              contact.rowVersion,
              contact,
              contact.officeId
            )
          );
          setIsRenameModalVisible(false);
        }}
        onClose={() => setIsRenameModalVisible(false)}
      >
        <ContactEditForm
          editableContact={editableContact}
          setEditableContact={setEditableContact}
        />
      </Modal>
    </Flex.Row>
  );
};

export default PersonnelFileHeader;

interface ContactEditFormProps {
  editableContact: EditableContact;
  setEditableContact: (contact: EditableContact) => void;
}

const ContactEditForm: React.FC<ContactEditFormProps> = (props) => {
  //#region ------------------------------ Defaults
  const { editableContact, setEditableContact } = props;
  const classes = useStyles();
  const [renameForm] = useForm();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const titleSuggestions: TitleSuggestion[] = useSelector<
    RootReducerState,
    TitleSuggestion[]
  >(getTitleSuggestions);

  const titleOptions = titleSuggestions
    .map((suggestion) => ({
      value: suggestion.value,
      searchValue: suggestion.value,
    }))
    ?.sort((a, b) => a.value.localeCompare(b.value));
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Form
      form={renameForm}
      initialValues={editableContact}
      layout="vertical"
      onValuesChange={(_, allValues) => {
        setEditableContact(allValues);
      }}
    >
      <Flex.Column>
        <Form.Item
          className={classes.formItem}
          name="firstName"
          label={t('hr:personnelFile.modal.labels.firstName')}
          rules={[
            {
              required: true,
              message: t('hr:personnelFile.modal.validation.missingFirstName'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className={classes.formItem}
          name="lastName"
          label={t('hr:personnelFile.modal.labels.lastName')}
          rules={[
            {
              required: true,
              message: t('hr:personnelFile.modal.validation.missingLastName'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className={classes.formItem}
          name="title"
          label={t('hr:personnelFile.modal.labels.title')}
        >
          {/* TODO: als select einfügen.Sinnvoll?  */}
          {/* <Input /> */}
          <Select options={titleOptions} />
        </Form.Item>
      </Flex.Column>
    </Form>
  );
};
