import React, { useState } from 'react';
import classNames from 'classnames';
import { PrioTheme } from '../../../../theme/types';
import { makePrioStyles } from '../../../../theme/utils';
import {
  ContactCompanyData,
  ContactCompanysCalculatedData,
  ContactSearchResultItem,
} from '../../../../models/Contact';
import useContactsContext from '../../hooks/useContactsProvider';
import useCompaniesContext from '../../../companies/hooks/useCompaniesContext';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { sortContactsHelper } from '../../utils';
import UserAvatar from '../../../../components/UserAvatar';
import { FilterBar } from '../../../../components/Filter/FilterBar';
import FilterContextVirtualTable from '../../../../components/Filter/FilterContextVirtualTable';
import FilterResultNoItemsScreen from '../../../../components/Filter/FilterResultNoItemsScreen';
import Flex from '../../../../components/Flex';
import { Button, Empty, VirtualTable } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.small,
  },
  body: { flex: 1 },
  content: { height: '100%', display: 'flex', flexDirection: 'column' },
  tablesWrapper: { flex: 1, display: 'flex', gap: 8 },
  tableWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    gap: 8,
  },
  header: {
    fontSize: theme.font.fontSize.small,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colors.application.typography.default,
  },
}));

interface AddInternalContactDrawerUserSelectStepProps {
  className?: string;
  setDrawerVisible: (visible: boolean) => void;
  setSelectedContacts: (contacts: ContactSearchResultItem[]) => void;
  selectedContacts: ContactSearchResultItem[];
  setCurrentStep: (step: number) => void;
}

export const AddInternalContactDrawerUserSelectStep: React.FC<
  AddInternalContactDrawerUserSelectStepProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    setDrawerVisible,
    setSelectedContacts,
    selectedContacts,
    setCurrentStep,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getContactById } = useContactsContext();
  const { getCompanyById } = useCompaniesContext();

  const { data, isLoading } = useFilterContext<
    ContactCompanyData,
    ContactCompanysCalculatedData
  >();

  const [contactsToAdd, setContactsToAdd] = useState<ContactSearchResultItem[]>(
    []
  );
  const [contactsToRemove, setContactsToRemove] = useState<
    ContactSearchResultItem[]
  >([]);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onAddSelectionChange = (selectedItems: ContactSearchResultItem[]) => {
    setContactsToAdd(selectedItems);
  };

  const onRemovalSelectionChange = (
    selectedItems: ContactSearchResultItem[]
  ) => {
    setContactsToRemove(selectedItems);
  };
  const onAddContactsToSelection = () => {
    setSelectedContacts([
      ...selectedContacts,
      ...contactsToAdd.filter((item) => !selectedContacts.includes(item)),
    ]);
    setContactsToAdd([]);
  };

  const onRemoveContactsFromSelection = () => {
    setSelectedContacts(
      selectedContacts.filter((item) => !contactsToRemove.includes(item))
    );
    setContactsToRemove([]);
  };

  const handleOnClose = () => {
    setDrawerVisible(false);
    setCurrentStep(0);
    setSelectedContacts([]);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Column
  const columns: Column<ContactSearchResultItem>[] = [
    {
      id: 'contactId',
      accessor: 'data.contact.contactId',
      title: t('contacts:projectContactsPage.columns.name'),
      width: 100,
      sortingFn: (rowA, rowB) =>
        sortContactsHelper(
          getContactById(rowA.data?.contact?.contactId),
          getContactById(rowB.data?.contact?.contactId)
        ),
      isDefaultSortingFn: true,
      Cell: ({
        originalData: {
          data: { contact },
          calculated: { name },
        },
      }) => (
        <>
          <UserAvatar
            contact={getContactById(contact?.contactId)}
            backgroundColor="#1a2f41"
            size="small"
          ></UserAvatar>
          <div
            style={{
              marginLeft: '8px',
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <div style={{ fontSize: '14px' }}>{name}</div>
            <div style={{ fontSize: '10px', fontWeight: '400' }}>
              {getCompanyById(contact?.companyId)?.fullName}
            </div>
          </div>
        </>
      ),
      alignSelf: true,
    },
  ];
  //#endregion

  const hiddenPickers = [
    'Data.CompanyId',
    'Calculated.InternalExternal',
    'Calculated.CompanyContact',
    'Data.FirstName',
    'Data.LastName',
    'Calculated.Signature',
    'Data.CommercialRegisterNumber',
    'Data.VATNumber',
  ];

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.body}>
        <div className={classes.content}>
          <FilterBar
            hiddenPickers={hiddenPickers}
            customAlwaysVisiblePickers={[
              'Data.OfficeId',
              'Transformed.ProjectIds',
              'Calculated.Name',
            ]}
          />
          <div className={classes.tablesWrapper}>
            <div className={classes.tableWrapper}>
              <div className={classes.header}>
                {t(
                  'contacts:addInternalContactDrawer.userSelectStep.searchTable.header'
                )}
              </div>
              <FilterContextVirtualTable<ContactSearchResultItem>
                id={'AddInternalContactDrawerTable'}
                className={classNames(classes.root, className)}
                columns={columns}
                data={data?.items ?? []}
                selectedItems={contactsToAdd}
                resizable="relative"
                onSelectionChange={onAddSelectionChange}
                actionBarButtons={[
                  {
                    iconProp: ['fal', 'plus'],
                    children: t(
                      'contacts:addInternalContactDrawer.userSelectStep.searchTable.add'
                    ),
                    onClick: onAddContactsToSelection,
                  },
                ]}
                onCheckEquality={({ calculated: a }, { calculated: b }) =>
                  a.name === b.name
                }
                noItemsScreen={<FilterResultNoItemsScreen />}
                loading={
                  isLoading && {
                    type: 'noItems',
                  }
                }
                rowsAreSelectable
                shortActionBarPrefix
              />
            </div>
            <div className={classes.tableWrapper}>
              <div className={classes.header}>
                {t(
                  'contacts:addInternalContactDrawer.userSelectStep.selectionTable.header'
                )}
              </div>
              <VirtualTable<ContactSearchResultItem>
                id={'AddInternalContactDrawerTable'}
                className={classNames(classes.root, className)}
                columns={columns}
                data={selectedContacts}
                selectedItems={contactsToRemove}
                resizable="relative"
                onSelectionChange={onRemovalSelectionChange}
                actionBarButtons={[
                  {
                    iconProp: ['fal', 'minus'],
                    children: 'Entfernen',
                    onClick: onRemoveContactsFromSelection,
                  },
                ]}
                onCheckEquality={({ calculated: a }, { calculated: b }) =>
                  a.name === b.name
                }
                noItemsScreen={
                  <Empty
                    customMessage={t(
                      'contacts:addInternalContactDrawer.userSelectStep.selectionTable.noItemsLabel'
                    )}
                  />
                }
                rowsAreSelectable
                shortActionBarPrefix
              />
            </div>
          </div>
        </div>
      </div>
      <Flex.Row childrenGap={8} justifyContent="flex-end">
        <Button type="default" onClick={handleOnClose}>
          {t('common:actions.cancel')}
        </Button>
        <Button
          disabled={selectedContacts.length === 0}
          onClick={() => setCurrentStep(1)}
        >
          {t('common:actions.next')}
        </Button>
      </Flex.Row>
    </div>
  );
};

export default AddInternalContactDrawerUserSelectStep;
