import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';
import { makePrioStyles } from '../../../theme/utils';
import {
  Button,
  Modal,
  Popover,
  PrioSpinner,
  Toggle,
} from '@prio365/prio365-react-library';
import { DriveItem } from '../../../models/Drive';
import { useTranslation } from 'react-i18next';
import {
  FolderPermissionAccessRight,
  FolderPermissionV2,
} from '../../../models/Settings';
import {
  FolderPermissionValue,
  GroupId,
  ProjectId,
} from '../../../models/Types';
import Flex from '../../../components/Flex';
import FolderPermissionSelect from '../../settings/components/FolderPermissionSelect';
import { useTheme } from 'react-jss';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  apiAddDriveItemPermissions,
  apiFetchFolderPermissions,
  apiRemoveNonPrioDriveItemPermissions,
} from '../api';
import { notification } from 'antd';

const selectValuesToApiData: (
  role: FolderPermissionAccessRight,
  value: FolderPermissionValue
) => FolderPermissionV2 = (role, value) => {
  const _canRead = value === 'read';
  const _canWrite = value === 'write';
  return {
    accessRights: role,
    canRead: _canWrite || _canRead || false,
    canWrite: _canWrite || false,
  };
};

const apiDataToSelectValues: (
  data: any[],
  role: FolderPermissionAccessRight
) => FolderPermissionValue = (data, role) => {
  const permission = data.find((permission) => permission.accessRight === role);

  if (permission?.roles?.includes('write')) return 'write';
  if (permission?.roles?.includes('read')) return 'read';
  return 'none';
};

const useStyles = makePrioStyles((theme) => ({
  root: {},
  folderNameRow: {
    marginBottom: theme.spacing.regular,
  },
  folderNameLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    maxWidth: '100%',
    fontWeight: theme.font.fontWeight.bold,
  },
}));

interface FolderAccessRightsModalProps {
  className?: string;
  projectId: ProjectId;
  driveItem: DriveItem;
  groupId: GroupId;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const FolderAccessRightsModal: React.FC<FolderAccessRightsModalProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, projectId, driveItem, groupId, visible, setVisible } =
    props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const queryClient = useQueryClient();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { data: _accessRights, isLoading } = useQuery({
    queryKey: [groupId, driveItem?.id, 'folderPermissions'],
    queryFn: () => apiFetchFolderPermissions(groupId, driveItem?.id),
    staleTime: 1000 * 60 * 60 * 20, // 20 hour
  });

  const accessRights = useMemo(
    () => _accessRights?.data || [],
    [_accessRights]
  );

  const [adminPermission, setAdminPermission] =
    useState<FolderPermissionValue>('none');

  const [assistancePermission, setAssistancePermission] =
    useState<FolderPermissionValue>('none');

  const [controllerPermission, setControllerPermission] =
    useState<FolderPermissionValue>('none');

  const [memberPermission, setMemberPermission] =
    useState<FolderPermissionValue>('none');

  const [guestPermission, setGuestPermission] =
    useState<FolderPermissionValue>('none');

  const [deleteSharepointPermissions, setDeleteSharepointPermissions] =
    useState(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const updateDriveItemPermissions = async () => {
    const permissions: FolderPermissionV2[] = [
      selectValuesToApiData('admin', adminPermission),
      selectValuesToApiData('assistance', assistancePermission),
      selectValuesToApiData('controller', controllerPermission),
      selectValuesToApiData('member', memberPermission),
      selectValuesToApiData('guest', guestPermission),
    ];

    const { result } = await apiAddDriveItemPermissions(
      projectId,
      groupId,
      driveItem.id,
      permissions
    );

    if (result.status >= 400) {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.updatePermissionsError'),
      });
    }
  };

  const deleteAllExistingPermissions = async () => {
    const { result } = await apiRemoveNonPrioDriveItemPermissions(
      projectId,
      driveItem.id,
      groupId
    );

    if (result.status >= 400) {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.deletePermissionsError'),
      });
    }
  };

  const updateMutation = useMutation(updateDriveItemPermissions, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        groupId,
        driveItem?.id,
        'folderPermissions',
      ]);
    },
  });
  const deleteMutation = useMutation(deleteAllExistingPermissions);

  const handleOk = async () => {
    if (deleteSharepointPermissions) {
      await deleteMutation.mutateAsync();
    }
    if (deleteMutation.isError) return;
    await updateMutation.mutateAsync();
    setVisible(false);
  };

  const handleOnClose = () => {
    setVisible(false);
    setDeleteSharepointPermissions(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setAdminPermission(apiDataToSelectValues(accessRights, 'admin'));
    setAssistancePermission(apiDataToSelectValues(accessRights, 'assistance'));
    setControllerPermission(apiDataToSelectValues(accessRights, 'controller'));
    setMemberPermission(apiDataToSelectValues(accessRights, 'member'));
    setGuestPermission(apiDataToSelectValues(accessRights, 'guest'));
  }, [accessRights]);
  //#endregion

  return (
    <Modal
      className={classNames(classes.root, className)}
      title={t('documents:folderAccessRightsModal.title')}
      visible={visible}
      onOk={handleOk}
      onClose={handleOnClose}
      okText={t('common:actions.save')}
      cancelText={t('common:actions.cancel')}
      okButtonProps={
        deleteMutation.isLoading || updateMutation.isLoading
          ? { loading: true, disabled: true }
          : {}
      }
      destroyOnClose
    >
      <Flex.Column childrenGap={theme.old.spacing.baseSpacing}>
        <Flex.Row alignItems="center" className={classes.folderNameRow}>
          <div style={{ paddingRight: theme.spacing.small }}>{`${t(
            'documents:folderAccessRightsModal.folderName'
          )}:`}</div>
          <span className={classes.folderNameLabel} title={driveItem?.name}>
            {driveItem?.name}
          </span>
        </Flex.Row>
        {!isLoading && (
          <>
            <FolderPermissionSelect
              field={'admin'}
              value={adminPermission}
              onChange={(value) => setAdminPermission(value)}
              showTitle
            />
            <FolderPermissionSelect
              field={'assistance'}
              value={assistancePermission}
              onChange={(value) => setAssistancePermission(value)}
              showTitle
            />
            <FolderPermissionSelect
              field={'controller'}
              value={controllerPermission}
              onChange={(value) => setControllerPermission(value)}
              showTitle
            />
            <FolderPermissionSelect
              field={'member'}
              value={memberPermission}
              onChange={(value) => setMemberPermission(value)}
              showTitle
            />
            <FolderPermissionSelect
              field={'guest'}
              value={guestPermission}
              onChange={(value) => setGuestPermission(value)}
              showTitle
            />
            <div
              style={{
                paddingTop: theme.spacing.large,
              }}
            >
              {t('documents:folderAccessRightsModal.resetExistingPermissions')}
            </div>
            <Toggle
              checked={deleteSharepointPermissions}
              onChange={(_, value) => {
                setDeleteSharepointPermissions(value);
              }}
            >
              <Popover
                content={t('documents:folderAccessRightsModal.tooltip')}
                trigger={['click']}
                placement="topLeft"
              >
                <Button
                  type="link"
                  iconProp={['fal', 'info-circle']}
                  style={{
                    background: 'transparent',
                    color: theme.colors.application.typography.muted,
                  }}
                />
              </Popover>
            </Toggle>
          </>
        )}
        {isLoading && (
          <Flex.Item alignSelf="center">
            <PrioSpinner />
          </Flex.Item>
        )}
      </Flex.Column>
    </Modal>
  );
};

export default FolderAccessRightsModal;
