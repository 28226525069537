import React from 'react';
import classNames from 'classnames';
import { PrioTheme } from '../../../../theme/types';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
}));

interface AddInternalContactDrawerUserConfigStepProps {
  className?: string;
}

export const AddInternalContactDrawerUserConfigStep: React.FC<
  AddInternalContactDrawerUserConfigStepProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return <div className={classNames(classes.root, className)}>User Config</div>;
};

export default AddInternalContactDrawerUserConfigStep;
