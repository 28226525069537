import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import CountryPicker from '../../../components/CountryPicker';
import CustomSingleDatePicker from '../../../components/CustomSingleDatePicker';
import Flex from '../../../components/Flex';
import { Employee } from '../../../models/Employee';
import { Gender, EmployeeId, ConfigurationKeys } from '../../../models/Types';
import { rowGutter, colon } from '../../../util/forms';
import {
  bicPattern,
  defaultPhonePattern,
  defaultPhonePlaceholder,
  ibanPattern,
} from '../../../util';
import { Configuration } from '../../../models/Configuration';
import { apiFetchConfigurations } from '../../settings/api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Button } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  form: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  fullWidth: {
    width: '100%',
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  heading: {
    marginBottom: theme.old.spacing.unit(2),
  },
}));

interface CoreDataFormModel {
  phone?: string;
  eMail?: string;
  street?: string;
  zip?: string;
  city?: string;
  countryCode?: string;
  federalStateCode?: string;
  gender?: Gender;
  nationality?: string;
  birthday?: Moment;
  allowShareBirthday?: boolean;
  allowShareBirthdayCompany?: boolean;
  birthplace?: string;
  iban?: string;
  bic?: string;
}

const coreDataFromEmployee: (employee: Employee) => CoreDataFormModel = (
  employee
) => ({
  phone: employee?.phone,
  eMail: employee?.eMail,
  street: employee?.street,
  zip: employee?.zip,
  city: employee?.city,
  countryCode: employee?.countryCode,
  federalStateCode: employee?.federalStateCode,
  gender: employee?.gender,
  nationality: employee?.nationality,
  birthday: employee?.birthday ? moment(employee?.birthday) : null,
  allowShareBirthday: employee?.allowShareBirthday,
  allowShareBirthdayCompany: employee?.allowShareBirthdayCompany,
  birthplace: employee?.birthplace,
  iban: employee?.iban,
  bic: employee?.bic,
});

interface EditPersonnelFileDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  onClose?: any;
  isSaving: boolean;
  datePickerLocale: PickerLocale;
  patchEmployee: (patchData: Employee) => Promise<void>;
}

export const EditPersonnelFileDataForm: React.FC<
  EditPersonnelFileDataFormProps
> = (props) => {
  const { employee, isSaving, patchEmployee, onClose } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [coreDataForm] = Form.useForm<CoreDataFormModel>();
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const cancelEditCoreData = () => {
    coreDataForm.setFieldsValue(coreDataFromEmployee(employee));
    onClose();
  };
  const submitCoreDataForm = () => {
    coreDataForm.submit();
  };

  const handleFinishCoreData = async (coreData: CoreDataFormModel) => {
    const { eMail, phone, ...workaround } = coreData;
    const patchData: Employee = {
      ...workaround,
      eMail,
      phone,
      birthday: coreData.birthday?.toISOString(true).split('T')[0],
    };
    await patchEmployee(patchData);
    onClose();
  };
  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    if (employee) {
      coreDataForm.setFieldsValue(coreDataFromEmployee(employee));
      const loadConfigs = async () => {
        const { data } = await apiFetchConfigurations();
        if (data) setConfigurations(data);
      };
      loadConfigs();
    }
  }, [employee, coreDataForm]);

  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Form<CoreDataFormModel>
        form={coreDataForm}
        onFinish={handleFinishCoreData}
        layout="vertical"
        className={classes.form}
      >
        <Flex.Column flex={1}>
          <Typography.Text type="secondary" className={classes.heading}>
            {t('hr:personnelFile.form.labels.address')}
          </Typography.Text>
          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                name="street"
                label={t('hr:personnelFile.form.labels.street')}
                colon={colon}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={8} style={{ paddingRight: theme.old.spacing.unit(1) }}>
              <Form.Item
                name="zip"
                label={t('hr:personnelFile.form.labels.zip')}
                colon={colon}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="city"
                label={t('hr:personnelFile.form.labels.city')}
                colon={colon}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                name="countryCode"
                label={t('hr:personnelFile.form.labels.countryCode')}
                colon={colon}
              >
                <CountryPicker />
              </Form.Item>
            </Col>
          </Row>
        </Flex.Column>
        <Divider style={{ marginTop: '0px' }} />
        <Flex.Column>
          <Typography.Text type="secondary" className={classes.heading}>
            {t('hr:personnelFile.form.labels.contactInformation')}
          </Typography.Text>
          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                name="eMail"
                label={t('hr:personnelFile.form.labels.eMail')}
                colon={colon}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: t('common:validation.email.error'),
                  },
                ]}
              >
                <Input disabled={isSaving} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                name="phone"
                label={t('hr:personnelFile.form.labels.phone')}
                colon={colon}
                rules={[
                  {
                    required: true,
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                    message: t('common:validation.phone.error'),
                  },
                ]}
              >
                <Input
                  disabled={isSaving}
                  placeholder={defaultPhonePlaceholder}
                />
              </Form.Item>
            </Col>
          </Row>
        </Flex.Column>
        <Divider style={{ marginTop: '0px' }} />
        <Flex.Column flex={1} marginTop={` ${theme.old.spacing.unit(2)}px`}>
          <Typography.Text type="secondary" className={classes.heading}>
            {t('hr:personnelFile.form.labels.other')}
          </Typography.Text>
          <Row gutter={rowGutter}>
            <Col span={8} style={{ paddingRight: theme.old.spacing.unit(1) }}>
              <Form.Item
                name="gender"
                label={t('hr:personnelFile.form.labels.gender')}
                colon={colon}
              >
                <Select placeholder={t('contacts:form.labels.gender')}>
                  <Select.Option value="male">
                    {t('contacts:form.gender.male')}
                  </Select.Option>
                  <Select.Option value="female">
                    {t('contacts:form.gender.female')}
                  </Select.Option>
                  <Select.Option value="diverse">
                    {t('contacts:form.gender.diverse')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="nationality"
                label={t('hr:personnelFile.form.labels.nationality')}
                colon={colon}
              >
                <CountryPicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={8} style={{ paddingRight: theme.old.spacing.unit(1) }}>
              <Form.Item
                name="birthday"
                label={t('hr:personnelFile.form.labels.birthday')}
                colon={colon}
                className={classes.fullWidth}
              >
                <CustomSingleDatePicker
                  id="edit_personnelfile_id"
                  anchorDirection={'ANCHOR_RIGHT'}
                  small={true}
                  regular={false}
                  twoMonths={false}
                  withFullScreenPortal={false}
                  daySize={30}
                  hideKeyboardShortcutsPanel={true}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="birthplace"
                label={t('hr:personnelFile.form.labels.birthplace')}
                colon={colon}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="allowShareBirthday"
                label={t('hr:personnelFile.form.labels.allowShareBirthday')}
                colon={colon}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="allowShareBirthdayCompany"
                label={t(
                  'hr:personnelFile.form.labels.allowShareBirthdayCompany'
                )}
                colon={colon}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        </Flex.Column>
        <Divider style={{ marginTop: '0px' }} />
        <Flex.Column flex={1} marginTop={` ${theme.old.spacing.unit(2)}px`}>
          <Typography.Text type="secondary" className={classes.heading}>
            {t('hr:personnelFile.pivot.bankData')}
          </Typography.Text>

          <Row gutter={rowGutter}>
            <Col span={12} style={{ paddingRight: theme.old.spacing.unit(1) }}>
              <Form.Item
                name="iban"
                label={t('hr:personnelFile.form.labels.iban')}
                colon={colon}
                rules={[
                  {
                    message: t('hr:personnelFile.errorMessages.invalidIban'),
                    pattern: ibanPattern,
                  },
                ]}
              >
                <Input disabled={isSaving} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="bic"
                label={t('hr:personnelFile.form.labels.bic')}
                colon={colon}
                rules={[
                  {
                    message: t('hr:personnelFile.errorMessages.invalidBic'),
                    pattern: bicPattern,
                  },
                ]}
              >
                <Input disabled={isSaving} />
              </Form.Item>
            </Col>
          </Row>
        </Flex.Column>
      </Form>
      <Flex.Column>
        <Flex.Row alignSelf="flex-end" childrenGap={theme.old.spacing.unit(1)}>
          <Button type="link" onClick={cancelEditCoreData} disabled={isSaving}>
            {t('common:actions.cancel')}
          </Button>

          <Button
            type={'primary'}
            onClick={submitCoreDataForm}
            disabled={isSaving}
          >
            {t('common:save')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </Flex.Column>
  );
};

export default EditPersonnelFileDataForm;
