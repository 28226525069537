import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Input } from 'antd';
import { Button, Dropdown } from '@prio365/prio365-react-library';
import { useDispatch } from 'react-redux';

import { makePrioStyles } from '../../../theme/utils';
import ContactSearch from './ContactSearch';
import useDebounce from '../../../hooks/useDebounce';
import { ContactId, CompanyId } from '../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openContactsDrawer } from '../actions/drawer';
import {} from '../../companies/actions/';
import classNames from 'classnames';
import Flex from '../../../components/Flex';
import { ContactProps, contactPropsArray } from './ContactSelectionList';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-tabs-content': {
      height: '100%',
    },
  },
  newContactActionButtonIcon: {
    fontSize: theme.old.typography.fontSize.base,
  },
  tabBar: {
    padding: theme.old.spacing.unit(3),
  },
  tabPane: {
    height: '100%',
  },
  contactSearch: {
    height: '100%',
  },
  search: {
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
          background: 'transparent',
          padding: 0,
          width: 32,
          color: 'rgba(0, 0, 0, 0.6)',
          '&:hover': {
            background: 'transparent',
          },
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
  },
  dropdownOption: {
    fontSize: theme.font.fontSize.regular,
    width: '100%',
    padding: '4px',
    paddingLeft: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.colors.application.background.hover,
      cursor: 'pointer',
    },
  },
  dropdownOptionActive: {
    position: 'relative',
    backgroundColor: theme.colors.application.background.selected,
    '&:hover': {
      backgroundColor: theme.colors.application.background.hover,
    },
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 3,
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  dropdownOptionCloseIcon: {
    padding: '4px',
    '&:hover': {
      color: theme.old.palette.primaryColor,
      cursor: 'pointer',
    },
  },
  searchSettingsDropdown: {
    backgroundColor: theme.colors.application.background.default,
    width: '140px',
    boxShadow:
      '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
  },
}));

interface ContactsTabsProps {
  className?: string;
  tabBarClassName?: string;
  activeKey?: 'all' | 'contacts' | 'companies';
  searchInput: string;
  setSearchInput: (value: string) => void;
  onChange?: (activeKey: 'all' | 'contacts' | 'companies') => void;
  onContactClick?: (contactId: ContactId) => void;
  onCompanyClick?: (companyId: CompanyId) => void;
  selectedContactProp?: ContactProps;
  setSelectedContactProp?: (value: ContactProps) => void;
}

export const ContactsTabs: React.FC<ContactsTabsProps> = (props) => {
  //#region -------------------------------- Variables
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    className,
    tabBarClassName,
    activeKey,
    searchInput,
    setSearchInput,
    onChange,
    onContactClick,
    onCompanyClick,
    selectedContactProp = undefined,
    setSelectedContactProp = () => {},
  } = props;
  const debouncedSearchInput = useDebounce(searchInput, 500);
  const dispatch = useDispatch();
  //#endregion

  //#region -------------------------------- State declaration
  const [isOnline, setIsOnline] = useState<boolean>(false);
  //#endregion

  //#region -------------------------------- Methods
  const showDrawerOfCurrentTab = () => {
    if (activeKey === 'all') {
      dispatch(openContactsDrawer({ view: 'contactNew' }));
    }
    if (activeKey === 'contacts') {
      dispatch(openContactsDrawer({ view: 'contactNew' }));
    }
    if (activeKey === 'companies') {
      dispatch(openContactsDrawer({ view: 'companyNew' }));
    }
  };

  //#endregion

  //#region -------------------------------- Hooks

  //#endregion

  //#region -------------------------------- Components
  const newContactActionButton = (
    <Button
      type="primary"
      onClick={showDrawerOfCurrentTab}
      iconProp={['fal', 'plus']}
    ></Button>
  );

  //#endregion

  return (
    <Tabs
      className={classNames(classes.root, className)}
      animated={false}
      defaultActiveKey="all"
      activeKey={activeKey}
      onChange={onChange}
      tabBarExtraContent={newContactActionButton}
      renderTabBar={(props, DefaultTabBar) => (
        <div className={classNames(classes.tabBar, tabBarClassName)}>
          <DefaultTabBar {...props} />
          <SearchInput
            searchInput={searchInput}
            isOnline={isOnline}
            setSearchInput={setSearchInput}
            selectedContactProp={selectedContactProp}
            setSelectedContactProp={setSelectedContactProp}
          />
        </div>
      )}
      destroyInactiveTabPane
    >
      <TabPane
        tab={t('contacts:taskPane.tabs.all')}
        key="all"
        className={classes.tabPane}
      >
        <TabContent
          type="all"
          searchTerm={debouncedSearchInput}
          onContactClick={onContactClick}
          onCompanyClick={onCompanyClick}
          setIsOnline={setIsOnline}
          isOnline={isOnline}
          selectedContactProp={selectedContactProp}
        />
      </TabPane>
      <TabPane
        tab={t('contacts:taskPane.tabs.contacts')}
        key="contacts"
        className={classes.tabPane}
      >
        <TabContent
          type="allContacts"
          searchTerm={debouncedSearchInput}
          onContactClick={onContactClick}
          onCompanyClick={onCompanyClick}
          setIsOnline={setIsOnline}
          isOnline={isOnline}
          selectedContactProp={selectedContactProp}
        />
      </TabPane>
      <TabPane
        tab={t('contacts:taskPane.tabs.companies')}
        key="companies"
        className={classes.tabPane}
      >
        <TabContent
          type="allCompanies"
          searchTerm={debouncedSearchInput}
          onContactClick={onContactClick}
          onCompanyClick={onCompanyClick}
          setIsOnline={setIsOnline}
          isOnline={isOnline}
          selectedContactProp={selectedContactProp}
        />
      </TabPane>
    </Tabs>
  );
};

export default ContactsTabs;

interface SearchInputProps {
  searchInput: string;
  isOnline: boolean;
  setSearchInput: (value: string) => void;
  selectedContactProp: ContactProps;
  setSelectedContactProp: (value: ContactProps) => void;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    searchInput,
    isOnline,
    setSearchInput,
    selectedContactProp,
    setSelectedContactProp,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [currentInput, setCurrentInput] = useState<string>('');
  const [timer, setTimer] = useState<any>(null);
  const [debouncedCurrentInput, setDebouncedCurrentInput] =
    useState<string>('');

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    setCurrentInput(text);
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        setSearchInput(text);
        setDebouncedCurrentInput(text);
      }, 250)
    );
  };

  const onDropdownOptionClicked = (e, prop: ContactProps) => {
    setSelectedContactProp(prop);
    setDropdownVisible(false);
  };

  const onResetDropdownOptionClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedContactProp(null);
    setDropdownVisible(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (searchInput !== debouncedCurrentInput) {
      setCurrentInput(searchInput);
      setDebouncedCurrentInput(searchInput);
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [searchInput, debouncedCurrentInput, timer]);
  //#endregion

  return (
    <Flex.Row childrenGap={theme.spacing.small}>
      <Input.Search
        allowClear
        size="middle"
        placeholder={t('contacts:contactSelectionList.search.placeHolder')}
        onChange={handleChange}
        value={currentInput}
        className={classes.search}
        enterButton={isOnline && <FontAwesomeIcon icon={['fal', 'globe']} />}
      />
      <Dropdown
        visible={dropdownVisible}
        placement="bottomRight"
        trigger={['click']}
        overlayClassName={classes.searchSettingsDropdown}
        onVisibleChange={setDropdownVisible}
        overlay={
          <>
            {contactPropsArray.map((prop) => {
              return (
                <div
                  className={classNames(classes.dropdownOption, {
                    [classes.dropdownOptionActive]:
                      prop === selectedContactProp,
                  })}
                  onClick={(e) => {
                    onDropdownOptionClicked(e, prop);
                  }}
                >
                  {t(`contacts:contactSelectionList.searchSettings.${prop}`)}
                  {prop === selectedContactProp && (
                    <FontAwesomeIcon
                      icon={['fal', 'times']}
                      className={classes.dropdownOptionCloseIcon}
                      onClick={(e) => onResetDropdownOptionClicked(e)}
                    />
                  )}
                </div>
              );
            })}
          </>
        }
      >
        <div
          onContextMenu={(e) => {
            e.preventDefault();
            setSelectedContactProp(undefined);
          }}
        >
          <Button
            type={selectedContactProp ? 'primary' : 'default'}
            tooltip={
              selectedContactProp
                ? t(`contacts:contactSelectionList.tooltip`, {
                    prop: t(
                      `contacts:contactSelectionList.searchSettings.${selectedContactProp}`
                    ),
                  })
                : undefined
            }
            tooltipPosition="topRight"
            iconProp={['fal', 'gear']}
            iconColor={
              selectedContactProp ? theme.colors.base.white.default : undefined
            }
          />
        </div>
      </Dropdown>
    </Flex.Row>
  );
};

interface TabContentProps {
  type: 'all' | 'allContacts' | 'allCompanies';
  searchTerm: string;
  onContactClick?: (contactId: ContactId) => void;
  onCompanyClick?: (companyId: CompanyId) => void;
  searchTypeChanged?: (isOnline: boolean) => void;
  setIsOnline?: (value: boolean) => void;
  isOnline?: boolean;
  selectedContactProp: ContactProps;
}

const TabContent: React.FC<TabContentProps> = (props) => {
  const {
    type,
    searchTerm,
    onContactClick,
    onCompanyClick,
    setIsOnline,
    isOnline,
    selectedContactProp,
  } = props;
  const classes = useStyles();

  const list = useMemo(
    () => (
      <ContactSearch
        type={type}
        searchTerm={searchTerm}
        onContactClick={onContactClick}
        onCompanyClick={onCompanyClick}
        className={classes.contactSearch}
        searchTypeChanged={setIsOnline}
        isOnlineFromOuter={isOnline}
        selectedContactProp={selectedContactProp}
      />
    ),
    [
      type,
      searchTerm,
      onContactClick,
      onCompanyClick,
      classes,
      setIsOnline,
      isOnline,
      selectedContactProp,
    ]
  );
  return list;
};
