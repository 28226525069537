import { ApiResult, apiUrl } from '../../../api';
import { ProjectId } from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const sendChatMessage: (
  userMessage: string,
  type: string,
  messageId: string,
  includeDraft?: boolean,
  projectId?: ProjectId
) => Promise<ApiResult<string>> = async (
  userMessage,
  type,
  messageId,
  includeDraft,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/cognitive/${type}/${messageId}?${
      projectId === 'me' ? '' : `projectId=${projectId}&`
    }userMessage=${encodeURIComponent(userMessage)}${
      type === 'NewEmailAi' ? `&getDraft=${includeDraft}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiGetLastAiEmailResponse: (
  messageId: string
) => Promise<ApiResult<string>> = async (messageId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/cognitive/EmailAiCache/${messageId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiResetConversation: (
  messageId: string
) => Promise<ApiResult<void>> = async (messageId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/cognitive/EmailAiCache/${messageId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};
