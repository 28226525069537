import contactClassification, * as fromContactClassification from './contactClassification';
import companyClassification, * as fromCompanyClassification from './companyClassification';
import projectFileSystemStructure, * as fromProjectFileSystemStructure from './projectFileSystemStructure';
import titleSuggestions, * as fromTitleSuggestions from './titleSuggestions';
import lastSettingsLocation, * as fromLastSettingsLocation from './lastSettingsLocation';
import textBlocks, * as fromTextBlocks from './textBlocks';
import textBlockManagement, * as fromTextBlockManagement from './textBlockManagement';
import { combineReducers } from 'redux';
import {
  ContactClassification,
  CompanyClassification,
  ProjectFileSystemStructure,
} from '../../../models/Settings';
import {
  ProjectFileSystemStructureId,
  TextBlockId,
} from '../../../models/Types';
import { TitleSuggestion } from '../../../models/TitleSuggestion';
import { TextBlock } from '../../../models/TextBlock';
import aiSetting, * as fromAiSetting from './aiSetting';
export interface SettingsReducerState {
  contactClassification: ContactClassification[];
  companyClassification: CompanyClassification[];
  projectFileSystemStructure: fromProjectFileSystemStructure.ProjectFileSystemStructureState;
  titleSuggestions: fromTitleSuggestions.TitleSuggestionsState;
  textBlocks: fromTextBlocks.TextBlocksState;
  textBlockManagement: fromTextBlockManagement.TextBlockManagementState;
  lastSettingsLocation: string;
  aiSetting: fromAiSetting.AiSettingState;
}

export default combineReducers<SettingsReducerState>({
  contactClassification,
  companyClassification,
  projectFileSystemStructure,
  titleSuggestions,
  textBlocks,
  textBlockManagement,
  lastSettingsLocation,
  aiSetting,
});

export const getContactClassifications: (
  state: SettingsReducerState
) => ContactClassification[] = (state) =>
  fromContactClassification.getContactClassifications(
    state.contactClassification
  );
export const getCompanyClassifications: (
  state: SettingsReducerState
) => CompanyClassification[] = (state) =>
  fromCompanyClassification.getCompanyClassifications(
    state.companyClassification
  );
export const getProjectFileSystemStructures: (
  state: SettingsReducerState
) => ProjectFileSystemStructure[] = (state) =>
  fromProjectFileSystemStructure.getProjectFileSystemStructures(
    state.projectFileSystemStructure
  );
export const getProjectFileSystemStructure: (
  state: SettingsReducerState,
  projectFileSystemStructureId: ProjectFileSystemStructureId
) => ProjectFileSystemStructure = (state, projectFileSystemStructureId) =>
  fromProjectFileSystemStructure.getProjectFileSystemStructure(
    state.projectFileSystemStructure,
    projectFileSystemStructureId
  );
export const getProjectFileSystemStructureIsFetching: (
  state: SettingsReducerState
) => boolean = (state) =>
  fromProjectFileSystemStructure.getIsFetching(
    state.projectFileSystemStructure
  );
export const getProjectFileSystemStructureHasError: (
  state: SettingsReducerState
) => boolean = (state) =>
  fromProjectFileSystemStructure.getHasError(state.projectFileSystemStructure);
export const getProjectFileSystemStructureHasUpdateError: (
  state: SettingsReducerState
) => boolean = (state) =>
  fromProjectFileSystemStructure.getHasUpdateError(
    state.projectFileSystemStructure
  );
export const getProjectFileSystemStructureErrorMessage: (
  state: SettingsReducerState
) => string = (state) =>
  fromProjectFileSystemStructure.getErrorMessage(
    state.projectFileSystemStructure
  );

export const getTitleSuggestions: (
  state: SettingsReducerState
) => TitleSuggestion[] = (state) =>
  fromTitleSuggestions.getTitleSuggestions(state.titleSuggestions);
export const getTitleSuggestionsIsFetching: (
  state: SettingsReducerState
) => boolean = (state) =>
  fromTitleSuggestions.getIsFetching(state.titleSuggestions);
export const getTitleSuggestionsHasError: (
  state: SettingsReducerState
) => boolean = (state) =>
  fromTitleSuggestions.getHasError(state.titleSuggestions);
export const getTitleSuggestionsErrorMessage: (
  state: SettingsReducerState
) => string = (state) =>
  fromTitleSuggestions.getErrorMessage(state.titleSuggestions);

export const getTextBlocks: (state: SettingsReducerState) => TextBlock[] = (
  state
) => fromTextBlocks.getTextBlocks(state.textBlocks);
export const getTextBlockById: (
  state: SettingsReducerState,
  textBlockId: TextBlockId
) => TextBlock = (state, textBlockId) =>
  fromTextBlocks.getTextBlockById(state.textBlocks, textBlockId);

export const textBlockManagementGetSelectedEntry: (
  state: SettingsReducerState
) => TextBlock | null = (state) =>
  fromTextBlockManagement.textBlockManagementGetSelectedEntry(
    state.textBlockManagement
  );
export const textBlockManagementGetOpen: (
  state: SettingsReducerState
) => boolean = (state) =>
  fromTextBlockManagement.textBlockManagementGetOpen(state.textBlockManagement);

export const getLastSettingsLocation: (state: any) => string = (state) =>
  fromLastSettingsLocation.getLastSettingsLocation(state.lastSettingsLocation);

export const getTextBlocksIsFetching: (
  state: SettingsReducerState
) => boolean = (state) => fromTextBlocks.getIsFetching(state.textBlocks);
export const getTextBlocksHasError: (state: SettingsReducerState) => boolean = (
  state
) => fromTextBlocks.getHasError(state.textBlocks);
export const getTextBlocksErrorMessage: (
  state: SettingsReducerState
) => string = (state) => fromTextBlocks.getErrorMessage(state.textBlocks);

export const getIsKeyEnabled: (
  state: SettingsReducerState,
  key: string
) => boolean = (state, key) =>
  fromAiSetting.getIsKeyEnabled(state.aiSetting, key);
