import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import {
  TimeKeepingDay,
  TimeKeepingDayCalculatedData,
  TimeKeepingDaySearchResultItem,
} from '../../../../models/TimeKeeping';
import ContactText from '../../../contacts/components/ContactText';
import { Button } from '@prio365/prio365-react-library';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import FilterResultNoItemsScreen from '../../../../components/Filter/FilterResultNoItemsScreen';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOfficesContext from '../../../companies/hooks/useOfficesContext';
import { OfficeId } from '../../../../models/Types';
import { Dropdown, Menu, Modal, notification } from 'antd';
import Flex from '../../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import {
  apiApproveTimeKeepingDay,
  apiRejectTimeKeepingDay,
} from '../../../timeKeeping/api';
import { useQueryClient } from '@tanstack/react-query';
import FilterContextVirtualTable from '../../../../components/Filter/FilterContextVirtualTable';
import { sortContactsHelper } from '../../../contacts/utils';
import { generateFilterOptions } from '../../../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  cellCentered: {
    justifyContent: 'center',
  },
  row: {
    cursor: 'pointer',
    '& button': {
      visibility: 'hidden',
    },
    '&:hover button': {
      visibility: 'visible',
    },
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dropdown: {
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
  },
}));

export interface HRTimekeepingPageTableRef {
  getSelectedTimekeepingDays: () => TimeKeepingDaySearchResultItem[];
  fetchTimekeepingDays: () => void;
}

interface HRTimekeepingPageTableProps {
  className?: string;
  tableId: string;
  onRowClick?: (entry: TimeKeepingDaySearchResultItem) => void;
  onRowSelectionChange?: (
    selectedMonthlyClose: TimeKeepingDaySearchResultItem[]
  ) => void;
  selectedSearchItems: TimeKeepingDaySearchResultItem[];
}

export const HRMonthlyClosePageTable = forwardRef(
  (
    props: HRTimekeepingPageTableProps,
    ref: React.Ref<HRTimekeepingPageTableRef>
  ) => {
    //#region ------------------------------ Defaults
    const {
      className,
      tableId,
      selectedSearchItems,
      onRowClick,
      onRowSelectionChange,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const { getContactById } = useContactsContext();
    const { getOfficeById } = useOfficesContext();

    const { data, isLoading, fetchSearch } = useFilterContext<
      TimeKeepingDay,
      TimeKeepingDayCalculatedData
    >();

    const timeKeepingDays = useMemo(() => {
      return (
        data?.items?.sort(
          (
            a: TimeKeepingDaySearchResultItem,
            b: TimeKeepingDaySearchResultItem
          ) => {
            return moment(a.data.timeKeepingEntries[0].startTime).diff(
              moment(b.data.timeKeepingEntries[0].startTime)
            );
          }
        ) ?? []
      );
    }, [data?.items]);

    const [itemToApprove, setItemToApprove] = useState<
      TimeKeepingDaySearchResultItem | undefined
    >(undefined);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const onSelectionChange = (items: TimeKeepingDaySearchResultItem[]) => {
      if (onRowSelectionChange) {
        onRowSelectionChange(items);
      }
    };

    const handleOnRow: (
      item: TimeKeepingDaySearchResultItem
    ) => VirtualListItemOnRowProps = useCallback(
      (item) => {
        return {
          onClick: (e) => {
            if (onRowClick) {
              onRowClick(item);
            }
          },
          className: classes.row,
        };
      },
      [classes, onRowClick]
    );

    const getEmployeeLabelById = useCallback(
      (id: string): string => {
        const employee = getContactById(id);
        return employee ? `${employee.firstName} ${employee.lastName}` : '';
      },
      [getContactById]
    );
    const employeeOptions = useMemo(() => {
      return generateFilterOptions<TimeKeepingDaySearchResultItem>(
        timeKeepingDays || [],
        'data.employeeId',
        getEmployeeLabelById
      );
    }, [timeKeepingDays, getEmployeeLabelById]);

    const officeOptions = useMemo(() => {
      return generateFilterOptions<TimeKeepingDaySearchResultItem>(
        timeKeepingDays || [],
        'calculated.officeId',
        (id: string) => {
          const office = getOfficeById(id as OfficeId);
          return office ? office.name : '';
        }
      );
    }, [timeKeepingDays, getOfficeById]);

    const typeOptions = useMemo(() => {
      return generateFilterOptions<TimeKeepingDaySearchResultItem>(
        timeKeepingDays || [],
        'data.type',
        (type: string) =>
          t(`hr:timeAndLeaveManagement.timekeepingDaysTable.type.${type}`)
      );
    }, [timeKeepingDays, t]);

    const stateOptions = useMemo(() => {
      return generateFilterOptions<TimeKeepingDaySearchResultItem>(
        timeKeepingDays || [],
        'data.state',
        (state: string) =>
          t(`hr:timeAndLeaveManagement.timekeepingDaysTable.state.${state}`)
      );
    }, [timeKeepingDays, t]);

    const showColumnsFilter = useMemo(() => {
      return timeKeepingDays?.length > 0;
    }, [timeKeepingDays]);
    //#endregion

    //#region ------------------------------ Components
    const menu = useCallback(
      (item: TimeKeepingDaySearchResultItem) => {
        return (
          <Menu
            onClick={(e) => {
              e.domEvent.stopPropagation();
            }}
          >
            <Menu.Item
              id="1"
              onClick={(e) => {
                e.domEvent.preventDefault();
                setItemToApprove(item);
              }}
              disabled={item.data.state !== 'approvalRequested'}
            >
              {t(
                'hr:timeAndLeaveManagement.timekeepingDaysTable.contextMenu.approveReject'
              )}
            </Menu.Item>
          </Menu>
        );
      },
      [t]
    );
    //#endregion

    //#region ------------------------------ Columns
    const columns: Column<TimeKeepingDaySearchResultItem>[] = [
      {
        Cell: ({
          originalData: {
            data: { state },
          },
        }) => {
          return (
            state === 'locked' && (
              <div
                title={t(
                  'hr:timeAndLeaveManagement.timekeepingDaysTable.locked'
                )}
              >
                <FontAwesomeIcon icon={['fal', 'lock']} />
              </div>
            )
          );
        },
        title: () => <div></div>,
        width: 3,
        minWidth: 34,
        id: 'status',
        accessor: 'data.state',
        className: classNames(classes.cell, classes.cellCentered),
        alignSelf: true,
      },
      {
        id: 'contactId',
        width: 20,
        title: t('hr:timeAndLeaveManagement.timekeepingDaysTable.employee'),
        accessor: 'data.employeeId',
        filterObject: showColumnsFilter
          ? {
              id: 'contactId',
              options: employeeOptions,
            }
          : undefined,
        sortingFn: (rowA, rowB) =>
          sortContactsHelper(
            getContactById(rowA.data.employeeId),
            getContactById(rowB.data.employeeId)
          ),
        Cell: ({
          originalData: {
            data: { employeeId },
          },
        }) => <ContactText contactId={employeeId?.toLowerCase()} hasTitle />,
        className: classes.cell,
        alignSelf: true,
      },
      {
        id: 'officeId',
        width: 18,
        title: t('hr:timeAndLeaveManagement.timekeepingDaysTable.office'),
        accessor: 'calculated.officeId',
        filterObject: showColumnsFilter
          ? {
              id: 'officeId',
              options: officeOptions,
            }
          : undefined,
        sortingFn: (rowA, rowB) => {
          const a = getOfficeById(rowA.calculated.officeId?.toLowerCase());
          const b = getOfficeById(rowB.calculated.officeId?.toLowerCase());
          return a?.name?.localeCompare(b?.name);
        },
        Cell: ({
          originalData: {
            calculated: { officeId },
          },
        }) => getOfficeById(officeId?.toLowerCase() as OfficeId)?.name,
        className: classes.cell,
        alignSelf: true,
      },
      {
        id: 'day',
        width: 12,
        title: t('hr:timeAndLeaveManagement.timekeepingDaysTable.date'),
        accessor: 'data.timeKeepingEntries',
        Cell: ({
          originalData: {
            data: { timeKeepingEntries },
          },
        }) => (
          <div>
            {moment(timeKeepingEntries?.[0]?.startTime).format('DD.MM.YYYY')}
          </div>
        ),
        sortingFn: (rowA, rowB) => {
          const dateA = moment(rowA?.data.timeKeepingEntries?.[0]?.startTime);
          const dateB = moment(rowB?.data.timeKeepingEntries?.[0]?.startTime);
          return dateA.diff(dateB);
        },
        className: classes.cell,
        alignSelf: true,
      },
      {
        id: 'hours',
        width: 11,
        title: t('hr:timeAndLeaveManagement.timekeepingDaysTable.hours'),
        accessor: 'calculated.workedHours',
        sortingFn: (rowA, rowB) => {
          const workedHoursA = rowA?.calculated.workedHours;
          const workedHoursB = rowB?.calculated.workedHours;
          return workedHoursA - workedHoursB;
        },
        Cell: ({
          originalData: {
            calculated: { workedHours },
          },
        }) => {
          const transformHours = (workedHours: number) => {
            const hours = Math.floor(workedHours);
            const _minutes = Math.floor((workedHours - hours) * 60);
            const minutes = _minutes === 0 ? '00' : _minutes;
            return `${hours}:${minutes} h`;
          };
          return transformHours(workedHours);
        },
        className: classNames(classes.cell),
        alignSelf: true,
      },
      {
        id: 'type',
        width: 15,
        title: t('hr:timeAndLeaveManagement.timekeepingDaysTable.type.header'),
        accessor: 'data.type',
        filterObject: showColumnsFilter
          ? {
              id: 'type',
              options: typeOptions,
            }
          : undefined,
        sortingFn: (rowA, rowB) => {
          const a = t(
            'hr:timeAndLeaveManagement.timekeepingDaysTable.type.' +
              rowA?.data.type
          );
          const b = t(
            'hr:timeAndLeaveManagement.timekeepingDaysTable.type.' +
              rowB?.data.type
          );
          const typeCompare = a?.localeCompare(b);
          return typeCompare;
        },
        Cell: ({
          originalData: {
            data: { type },
          },
        }) => t(`hr:timeAndLeaveManagement.timekeepingDaysTable.type.${type}`),
        className: classes.cell,
        alignSelf: true,
      },
      {
        id: 'state',
        width: 15,
        title: t('hr:timeAndLeaveManagement.timekeepingDaysTable.state.header'),
        accessor: 'data.state',
        filterObject: showColumnsFilter
          ? {
              id: 'state',
              options: stateOptions,
            }
          : undefined,
        sortingFn: (rowA, rowB) => {
          const stateCompare = t(
            `hr:timeAndLeaveManagement.timekeepingDaysTable.state.${rowA?.data.state}`
          )?.localeCompare(
            t(
              `hr:timeAndLeaveManagement.timekeepingDaysTable.state.${rowB?.data.state}`
            )
          );
          return stateCompare;
        },
        Cell: ({
          originalData: {
            data: { state },
          },
        }) => (
          <div
            title={t(
              `hr:timeAndLeaveManagement.timekeepingDaysTable.state.${state}`
            )}
            className={classes.ellipsis}
          >
            {t(`hr:timeAndLeaveManagement.timekeepingDaysTable.state.${state}`)}
          </div>
        ),
        className: classes.cell,
        alignSelf: true,
      },
      {
        id: 'options',
        title: '',
        width: 4,
        accessor: 'data.state',
        Cell: ({ originalData }) => (
          <Dropdown
            overlay={menu(originalData)}
            trigger={['click']}
            placement="bottomRight"
            overlayClassName={classes.dropdown}
          >
            <Button
              iconProp={['fal', 'ellipsis-v']}
              type="default"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Dropdown>
        ),
        className: classes.cell,
        alignSelf: true,
      },
    ];
    //#endregion

    //#region ------------------------------ Effects
    useImperativeHandle(ref, () => ({
      getSelectedTimekeepingDays: () => {
        return selectedSearchItems;
      },
      fetchTimekeepingDays: () => {
        fetchSearch();
      },
    }));
    //#endregion

    return (
      <>
        <FilterContextVirtualTable<TimeKeepingDaySearchResultItem>
          id={tableId}
          className={classNames(classes.root, className)}
          columns={columns}
          data={timeKeepingDays}
          selectedItems={selectedSearchItems}
          resizable="relative"
          onRow={handleOnRow}
          onSelectionChange={onSelectionChange}
          noItemsScreen={<FilterResultNoItemsScreen />}
          loading={
            isLoading && {
              type: 'noItems',
            }
          }
          onCheckEquality={(a, b) =>
            a.data.timeKeepingDayId === b.data.timeKeepingDayId
          }
          rowsAreSelectable
        />
        <TimekeepingDayApprovalModal
          itemToApprove={itemToApprove}
          setItemToApprove={setItemToApprove}
        />
      </>
    );
  }
);

export default HRMonthlyClosePageTable;

interface TimekeepingDayApprovalModalProps {
  itemToApprove: TimeKeepingDaySearchResultItem;
  setItemToApprove: (item: TimeKeepingDaySearchResultItem) => void;
}

const TimekeepingDayApprovalModal: React.FC<
  TimekeepingDayApprovalModalProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { itemToApprove, setItemToApprove } = props;
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { optimisticWrite } = useFilterContext<
    TimeKeepingDay,
    TimeKeepingDayCalculatedData
  >();

  const { getContactById } = useContactsContext();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleCloseTimeKeepingModal = () => {
    setItemToApprove(null);
  };

  const handleDenyTimekeepingDayRequest = async () => {
    optimisticWrite([
      {
        ...itemToApprove,
        data: {
          ...itemToApprove.data,
          state: 'notApproved',
        },
        method: 'update',
        callback: async () => {
          const timeKeepingDayId = itemToApprove?.data?.timeKeepingDayId;
          const officeId = itemToApprove?.calculated?.officeId;
          const { result, data } = await apiRejectTimeKeepingDay(
            timeKeepingDayId,
            officeId
          );

          if (result.ok) {
            queryClient.invalidateQueries({
              queryKey: ['timekeepingDays'],
              refetchType: 'all',
            });
          } else {
            notification.open({
              message: t('common:error'),
              description: t('timeKeeping:messages.errorMessages.rejectError'),
            });
          }
          return {
            result,
            data: data
              ? {
                  data,
                  calculated: itemToApprove.calculated,
                }
              : null,
          };
        },
      },
    ]);
    handleCloseTimeKeepingModal();
  };

  const handleApproveTimekeepingDayRequest = async () => {
    optimisticWrite([
      {
        ...itemToApprove,
        data: {
          ...itemToApprove.data,
          state: 'approved',
        },
        method: 'update',
        callback: async () => {
          const timeKeepingDayId = itemToApprove?.data?.timeKeepingDayId;
          const officeId = itemToApprove?.calculated?.officeId;
          const { result, data } = await apiApproveTimeKeepingDay(
            timeKeepingDayId,
            officeId
          );

          if (result.ok) {
            queryClient.invalidateQueries({
              queryKey: ['timekeepingDays'],
              refetchType: 'all',
            });
          } else {
            notification.open({
              message: t('common:error'),
              description: t('timeKeeping:messages.errorMessages.approveError'),
            });
          }
          return {
            result,
            data: data
              ? {
                  data,
                  calculated: itemToApprove.calculated,
                }
              : null,
          };
        },
      },
    ]);
    handleCloseTimeKeepingModal();
  };
  //#endregion

  return (
    <Modal
      visible={itemToApprove?.data?.timeKeepingDayId !== undefined}
      onCancel={handleCloseTimeKeepingModal}
      title={t('dashboard:hrTimeKeepingOverview.modal.title')}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      footer={
        <Flex.Row justifyContent="flex-end" childrenGap={theme.spacing.small}>
          <Button onClick={handleDenyTimekeepingDayRequest} type="link">
            {t('dashboard:hrTimeKeepingOverview.modal.cancelText')}
          </Button>
          <Button type="primary" onClick={handleApproveTimekeepingDayRequest}>
            {t('dashboard:hrTimeKeepingOverview.modal.okText')}
          </Button>
        </Flex.Row>
      }
    >
      <Flex.Column childrenGap={theme.old.spacing.unit(1)}>
        <Flex.Item>
          <span>
            {`${t('dashboard:hrTimeKeepingOverview.modal.content1', {
              name: `${getContactById(itemToApprove?.data?.employeeId)
                ?.firstName} ${getContactById(itemToApprove?.data?.employeeId)
                ?.lastName}`,
            })} ${moment(
              itemToApprove?.data?.timeKeepingEntries[0]?.startTime
            ).format('dddd')}, `}
          </span>
          <strong>
            {moment(
              itemToApprove?.data?.timeKeepingEntries[0]?.startTime
            ).format('DD. MMMM YYYY')}
          </strong>
          <span>
            {`, ${t('dashboard:hrTimeKeepingOverview.modal.content2')} `}
          </span>
          <strong>
            {moment(
              itemToApprove?.data?.timeKeepingEntries?.sort((a, b) =>
                moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
              )[0]?.startTime
            ).format('H:mm')}
          </strong>
          <span>
            {` ${t('dashboard:hrTimeKeepingOverview.modal.content3')} `}
          </span>
          <strong>
            {moment(
              itemToApprove?.data?.timeKeepingEntries?.sort((a, b) =>
                moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
              )[itemToApprove?.data?.timeKeepingEntries?.length - 1]?.endTime
            ).format('H:mm')}
          </strong>
          <span>{` ${t(
            'dashboard:hrTimeKeepingOverview.modal.content4'
          )}`}</span>
        </Flex.Item>
        {itemToApprove?.data?.notes && (
          <Flex.Item>
            <Flex.Column>
              <div>
                {`${t('dashboard:hrTimeKeepingOverview.modal.content5')}`}
              </div>
              <div>{itemToApprove?.data?.notes}</div>
            </Flex.Column>
          </Flex.Item>
        )}
        <Flex.Item>
          <span>{` ${t(
            'dashboard:hrTimeKeepingOverview.modal.content6'
          )}`}</span>
        </Flex.Item>
      </Flex.Column>
    </Modal>
  );
};
