import React from 'react';
import { OfficeId } from '../../../models/Types';
import { HRAbsenceProposalPage } from './HRAbsenceProposalPage';
import { DefaultSearchParameterItem } from '../../../components/Filter/types';

interface HRAbsenceOpenProposalPageProps {
  className?: string;
  officeId?: OfficeId;
}

export const HRAbsenceOpenProposalPage: React.FC<
  HRAbsenceOpenProposalPageProps
> = ({ className, officeId }) => {
  const customDefaultSearchParameters: DefaultSearchParameterItem[] = [
    {
      parameterName: 'Data.AbsenceState',
      defaultValue: ['requested', 'revokeRequested'],
      defaultMethod: 'eq',
    },
    {
      parameterName: 'Transformed.Timespan',
      defaultValue: 'n6m',
      defaultMethod: 'rel',
    },
    ...(officeId
      ? [
          {
            parameterName: 'Data.OfficeId',
            defaultValue: officeId,
            defaultMethod: 'eq',
          },
        ]
      : []),
  ];

  return (
    <HRAbsenceProposalPage
      className={className}
      officeId={officeId}
      customDefaultSearchParameters={customDefaultSearchParameters}
    />
  );
};

export default HRAbsenceOpenProposalPage;
